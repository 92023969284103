import authService from './auth';
import api from "./api";
import {action} from "mobx";
import { serialize } from '../helpers/index';
import {FilterModel, FilterDashboardModel} from "../models/filter"
import ErrorService from "./error";

class ApplicationService {

    #applications = [];
    dashboard = {};

    get applications() {
        return this.#applications;
    }

    set applications(applications) {
        this.#applications = applications;
    }

    saveApplication = (data) => {
        return api.post(
            '/applications',
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(new ErrorService(e).getError())
            });
    }

    getEvents = (id) => {
        return api.get(
            `/applications/${id}/events`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    getApplications = (id) => {
        return api.get(
            `/applications/${id}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    @action getApplicationsList = (filter) => {

        const queryString = serialize(new FilterModel(filter));

        return api.get(
            `/applications?${queryString}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    @action getDashboard = (filter) => {

        const queryString = serialize(new FilterDashboardModel(filter));

        return api.get(
            `/applications/dashboard?${queryString}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

}

const applicationService = new ApplicationService();
export default applicationService;
