import { observable, action } from "mobx";
import authService from "../services/auth";
import OAuth from "../services/OAuth";
import profileService from '../services/profile';
import { ProfileModel } from "../models/profile";


class UserStore {

    @observable profile = new ProfileModel();
//TODO инициализировать в false. придумать что-то вроде isLoading, чтобы не мигал index при запросе новых токенов
    @observable isAuthorized = null;

    constructor() {
        OAuth.init()
            .then(() => {
                this.autorizeOAuth();
                return profileService.getProfile2();
            })
            .then((data) => {
                this.setProfile(data);
            })
            .catch(error => {
                console.log(error);
            });

//TODO когда будет проверка авторизации в каждом запросе, буду думать как переделать
/*        authService.loadFromAppStorage().then(() => {
            return profileService.getProfile();
        })
            .then((data) => {
                this.setProfile(data);
            })
            .catch(() => {
            })
            .finally(() => {
                this.autorize();
            });
 */
    }

    get firstService() {
        return this.profile.roles[0].services[0];
    }

    get userFullName() {
        return `${this.profile.user.firstname} ${this.profile.user.lastname}`
    }

    get clientTitle() {
        return this.profile.client.title;
    }

    @action setProfile = (data) => {
        const result = data;

        if (data && result.client && result.user && result.roles) {
            this.profile = new ProfileModel(
                result.user,
                result.client,
                result.roles,
                result.allowed,
            )
        }
    }

    @action autorizeOAuth = () => {
        this.isAuthorized = OAuth.isAuthorized;
    }

    @action autorize = () => {
        this.isAuthorized = authService.isAuthorized;
    }

    @action login = (email, password, remember) => {
        return authService.auth({ username: email, password: password }, remember)
            .then(() => {
                return profileService.getProfile();
            })
            .then((data) => {
                this.setProfile(data);
                this.autorize();
            })
            .catch(e => {
                return e;
            })
    }

    @action logout = () => {
        authService.logout()
            .then(() => {
                this.autorize();
            })
            .catch(e => {
                return e;
            })
    }
}

export default new UserStore();
