import React, { Component } from "react";
import cn from 'classnames';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';



import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import InputPassword from "../../common/InputPassword";

import { Field, Formik } from "formik";
import { inject } from "mobx-react";

import '../../App.scss';
import './Login.scss';
import '../../common/ErrorPopup.scss';

import CloseIco from "../../../images/error_close_ico.svg";
import LoginForm from "../../../images/login_form.svg";
import { withRouter } from "react-router-dom";
import okAnalyticsService from "../../../services/okAnalytics";


@inject('userStore')
class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            success: false,
            isLoading: false,
            initForm: {
                email: '',
                password: '',
                remember: true
            }
        };
        this.userStore = this.props.userStore;
    }

    /**
     * Авторизация
     * @param {values} values данные из формы
     */
    onFormSubmit = (values) => {
        this.userStore.login(values.email, values.password, values.remember).then(response => {
            //response ? this.setState({ error: true }) : this.props.history.push('/Application/add');
            if(response) {
                this.setState({ error: true })
            }
            else {

                okAnalyticsService.addEntity({
                    page: 'login',
                    target: 'login_form',
                    event: 'login',
                });

                this.props.history.push('/Application/add');
            }
        });
    }

    /**
     * Проверка и установка css класса ошибки
     * @param {string|boolean|[]} isError
     */
    errorClass = (isError) => {
        return (isError === true) ? 'p-error' : '';
    }

    /**
     * Валдация формы
     * @param {Values} values
     * @returns {Object} errors
     */
    validateForm = (values) => {
        const errors = {};

        if (values.email === '') {
            errors.email = true;
        }
        if (values.email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Невалидный E-mail';
        }

        if (!values.password) errors.password = true;
        return errors;
    }

    render() {
        const {
            error,
            isLoading,
            initForm,
        } = this.state;

        let classNameForm = 'form';
        if (isLoading) {
            classNameForm += ' form_bg-transparent';
        }

        return (
            <React.Fragment>
                <div className={cn('page__login')}>
                    {error && <Dialog visible={error}
                                      className={cn('dialogError')}
                                      position={'center'}
                                      onHide={() => this.setState({ 'error': false })}
                    >
                        <div className={'blockError'}>
                            <div className={cn('wrapper', 'error')}>
                                <div className={cn('text')}
                                     dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_errorTextLogin }}
                                />
                                <div
                                    className={cn('close')}
                                    onClick={() => this.setState({ 'error': false })}
                                ><img src={CloseIco} alt="" width={'15px'} height={'15px'} /></div>
                            </div>
                        </div>
                    </Dialog>}

                    <div className={classNameForm}>
                        {isLoading && <div className={'loading'}><ProgressSpinner /></div>}
                        {!isLoading && <React.Fragment>
                            <div className={cn('form__img')}>
                                <img src={LoginForm} alt="" width={310} />
                            </div>
                            <div>
                                <h1>Вход</h1>
                                <Formik
                                    initialValues={initForm}
                                    validate={this.validateForm}
                                    onSubmit={(values) => {
                                        this.onFormSubmit(values);
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting

                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className={'text-field'}>
                                                <div className="p-float-label">
                                                    <InputText
                                                        id='email'
                                                        type="text"
                                                        name="email"
                                                        className={this.errorClass((errors.email && touched.email))}
                                                        touched={touched}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                    />
                                                    {(errors.email && touched.email) &&
                                                    <span className={'inputTextError'}>{errors.email}</span>}
                                                    <label htmlFor='email'>E-mail <span
                                                        style={{ color: 'red' }}
                                                    >*</span></label>
                                                </div>
                                            </div>
                                            <div className={'text-field'}>
                                                <div className="p-float-label">
                                                    <InputPassword
                                                        id='password'
                                                        type="password"
                                                        name="password"
                                                        className={this.errorClass((errors.password && touched.password))}
                                                        touched={touched}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        values={values.password}
                                                    >
                                                    </InputPassword>
                                                    <label htmlFor='password'>Пароль <span
                                                        style={{ color: 'red' }}
                                                    >*</span></label>
                                                </div>
                                            </div>
                                            <div className={'text-field'}>
                                                <Field name='remember'
                                                       id='remember'
                                                       type='checkbox'
                                                >
                                                    {({ field }) => (
                                                        <Checkbox inputId="remember"
                                                                  {...field}
                                                        />
                                                    )}
                                                </Field>
                                                <label htmlFor="remember" className={cn('checkbox-label')}>Запомнить
                                                    меня</label>

                                            </div>
                                            <div className={'text-field'}>
                                                <Button label='Войти' type="submit" className={cn('submit-btn')} />
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </React.Fragment>
                        }
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(LoginPage);
