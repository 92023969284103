import cn from "classnames";
import React, { useEffect, useState } from "react";
import _ from "underscore";
import { useIsMount } from "../../../../hooks";
import { inject, observer } from "mobx-react";
import { RequiresAttentionButton, FilterButton } from '../../../common/FilterButton';
import Calendar from "./Calendar";
import './Filter.scss';

const Filter = ({ reviewFilterStore, loading, shortFormat }) => {

    const { branches, statuses, scores, sources, claims, filter, setFieldValue } = reviewFilterStore;

    const [disabledFilter, setDisabledFilter] = useState(!!filter.attention[0].value);
    const [attentionNum, setAttentionNum] = useState(null);
    const isMount = useIsMount();

    useEffect(() => {
        reviewFilterStore.getBranches();
        reviewFilterStore.getSources();
    }, [])

    useEffect(() => {
        reviewFilterStore.loadAttentionNum().then(result => {
            setAttentionNum(result);
        });
    }, [JSON.stringify(filter)])

    const setValue = _.debounce((field, data) => {
        if (!isMount) {
            setFieldValue(field, data);
        }
    }, 800);

    const handlerChangeDate = (date) => {
        reviewFilterStore.setDate(date);
    }

    const clear = () => {
        reviewFilterStore.clear();
        setDisabledFilter(false);
    }

    return (
        <>
            <div className={cn('filter__header')}>
                <div className={'filter__title'}>
                    <h3>Фильтр</h3>
                </div>
                <div
                    className={'filter__clear-btn'}
                    onClick={clear}
                >Очистить все
                </div>
            </div>
            <div className={cn('filter__wrapper')}>
                <div className={'filter__item'}>
                    <Calendar
                        onChange={handlerChangeDate}
                        dateRange={filter.date}
                        shortFormat={shortFormat}
                        disabled={disabledFilter}
                    />
                </div>
                {branches && <div className={'filter__item'}>
                    <FilterButton
                        name='Филиалы'
                        list={branches}
                        onChange={(data) => setValue('branches', data)}
                        filter={filter.branches}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>}
                {sources &&<div className={'filter__item'}>
                    <FilterButton
                        name='Источник'
                        list={sources}
                        onChange={(data) => setValue('sources', data)}
                        filter={filter.sources}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>}
                <div className={'filter__item'}>
                    <FilterButton
                        name='Оценки'
                        list={scores}
                        onChange={(data) => setValue('scores', data)}
                        filter={filter.scores}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус ответа'
                        list={statuses}
                        onChange={(data) => setValue('answer', data)}
                        filter={filter.answer}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                <div className={'filter__item'}>
                    <FilterButton
                        name='Статус жалобы'
                        list={claims}
                        onChange={(data) => setValue('claim', data)}
                        filter={filter.claim}
                        disabled={disabledFilter}
                        loading={loading}
                    />
                </div>
                <div className={cn('filter__item')}
                >
                    <RequiresAttentionButton
                        name='Требует внимания'
                        active={disabledFilter}
                        handlerClick={() => {
                            setDisabledFilter(disabledFilter => !disabledFilter);
                            setValue('attention', [{ value: +(!disabledFilter) }]);
                        }}
                        selectedItems={attentionNum?.attention}
                    />
                </div>
            </div>
        </>
    )
}


export default inject('reviewFilterStore')(observer(Filter));
