import React from "react";
import { NavLink } from "react-router-dom";
import "./Tabs.scss";

const Tabs = () => {
    return (
        <div className={'tabs'}>
            <ul>
                <li>
                    <NavLink to='/analytics/applications'>Заявки</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/reviews'>Отзывы</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/branch'>Филиал</NavLink>
                </li>
                <li>
                    <NavLink to='/analytics/traffic'>Трафик</NavLink>
                </li>

            </ul>
        </div>
    );
}

export default Tabs;
