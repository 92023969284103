import React from "react";
import cn from "classnames";
import './AnswerList.scss';
import { ProgressSpinner } from "primereact/progressspinner";

const AnswerList = ({ items }) => {

    return (
        <div className={cn('replay__list')}>
            {!items.length && <div className={cn('replay__loader')} >
                <ProgressSpinner />
            </div>}
            {items.map((item, key) => (
                <div key={key} className={cn('replay', { 'official': item.official })}>
                    <div className={cn('replay__wrapper')}>
                        <div className={cn('replay__header')}>
                            <div className={cn('replay__author')}>
                                {item.official
                                    ?
                                    <span className={cn('replay__author_official')}>Оф. ответ</span>
                                    :
                                    <span>{item.author.name}</span>
                                }
                                {!item.published ? 'Ожидает публикации' : item.postedAt}
                            </div>
                        </div>
                        <div className={cn('replay__body')}>
                            {item.text}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AnswerList;
