import React, {useEffect} from "react";
import {YMInitializer} from 'react-yandex-metrika';
import {useLocation} from "react-router-dom";

const yaId = process.env.REACT_APP_yaMetrikaId;
const yaExtreviewsId = process.env.REACT_APP_yaMetrikaId_extreviews;

const hit = (pathname) => {
    if (yaId) {
        try {
            window[`yaCounter${yaId}`].hit(pathname);
        } catch (e) {
        }
    }

    if (yaExtreviewsId) {
        if (/^(\/review)|^(\/analytics)/.test(pathname)) {
            try {
                window[`yaCounter${yaExtreviewsId}`].hit(pathname);
            } catch (e) {
            }
        }
    }
};

const usePageViews = () => {
    let location = useLocation();

    useEffect(
        () => {
            hit(location.pathname);
        },
        [location]
    );
};

const YandexMetrika = () => {
    usePageViews();

    return (
        <>
            {yaId && <div
                style={{'position': 'absolute', 'left': '-9999px'}}
            >
                <YMInitializer
                    accounts={[yaId, yaExtreviewsId]}
                    options={
                        {
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                        }
                    }
                />
            </div>}
        </>
    );
}

export default YandexMetrika;
