import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

const RequiresAttentionButton = ({name = '', active= false, selectedItems= 0, handlerClick = () => {}}) => {
    return (
        <div
            className={cn('filter-button', 'warning')}
        >
            <div
                className={cn(
                    'filter-button__title',
                    {'active': active},
                )}
                onClick={() => {
                    handlerClick();
                }}
            >
                <TitleButton
                    title={name}
                    lengthSelectedItems={selectedItems}
                />
            </div>
        </div>
    );
}

const FilterButtonSimple = (props) => {
    return (
        <FilterButton
            {...props}
            simple={true}
            className={cn('fixed-width', {'fixed-width__mobile': props.mobile})}
        />
    )
}

/**
 *
 * @param {string} name
 * @param {Array} list
 * @param {Array} filter
 * @param {Function} onChange
 * @param {boolean} disabled
 * @param {boolean} loading
 * @param {boolean} simple
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
const FilterButton = ({
                          name,
                          list,
                          filter = [],
                          onChange = () => {},
                          disabled= false,
                          loading = false,
                          simple= false,
                          className = ''}) => {

    const buttonRef = useRef();
    const dropdownPlaceholder = useRef();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [isLoading, setLoading] = useState(loading);
    const [title, setTitle] = useState(name);

    useEffect(() => {

        if(simple && filter.length <= 0){
            setDefaultValue();
        }

        if(simple && filter.length > 0){
            setTitle(() => list.find(item => item.value === filter[0].value).name);
        }

        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        setSelectedItems(filter);
    }, [filter]);

    useEffect(() => {
        onChange(selectedItems);
    }, [selectedItems]);


    useEffect(() => {
        if(dropdownPlaceholder.current) {
            if(document.documentElement.clientWidth < dropdownPlaceholder.current.getBoundingClientRect().right) {
                dropdownPlaceholder.current.style.left = '-145px';
            }
        }
    }, [openDropdown]);

    const setDefaultValue = () => {
        filter.push({
            value: list[0].value
        });

    }

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const handlerChange = (data) => {
        if(!simple) {
            if (data.action === 'add') {
                setSelectedItems(selectedItems => [...selectedItems, {
                    value: data.value
                }]);
            }

            if (data.action === 'remove') {
                setSelectedItems(selectedItems => {
                    return selectedItems.filter(item => {
                        return item.value !== data.value;
                    });
                })
            }

        }
        else{
            if (data.action === 'add') {
                setSelectedItems([{
                    value: data.value
                }]);
                setTitle(data.name);
            }
        }
    }

    return (
        <div
            className={cn('filter-button', {'disabled' : disabled})}
            ref={buttonRef}
        >
            <div
                className={cn(
                    'filter-button__title',
                    { 'selected': openDropdown },
                    { 'active': !!selectedItems.length },
                    className
                )}
                onClick={() => {
                    !disabled && setOpenDropdown(openDropdown => !openDropdown);
                }}
            >
                <TitleButton
                    title={title}
                    selectedItems={selectedItems.length}
                    simple={simple}
                />
            </div>
            {openDropdown && <div className={cn('filter-button__dropdown')} ref={dropdownPlaceholder}>
                {isLoading && <div className={cn('filter-button__dropdown-loading')}/>}
                <ul>
                    {list && list.map((item, key) => {
                        const selectedItem = !!selectedItems.filter(select => select.value === item.value).length
                        return (
                            <Row
                                key={key}
                                item={item}
                                isSelect={selectedItem}
                                handlerChange={handlerChange}
                            />
                        );
                    })}
                </ul>
            </div>}
        </div>
    );
}

const Row = ({ item, isSelect, handlerChange }) => {
    return (
        <li className={cn({ 'select': isSelect })}
            onClick={() => {
                const action = isSelect ? 'remove' : 'add';
                handlerChange({
                    value: item.value,
                    name: item.name,
                    action,
                })
            }}
        >
            {isSelect && <i className="pi pi-check" />}
            {item.name}</li>
    );
};

const TitleButton = ({ title, selectedItems, simple = false }) => {

    return (
        <>
            {title} {simple ? null : (selectedItems >= 1) && <>: <span>{selectedItems}</span></>}
        </>
    )
};

export {
    RequiresAttentionButton,
    FilterButtonSimple,
    FilterButton,
}
