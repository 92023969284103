import React, { Component } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import _ from "underscore";

import applicationService from '../../../../../services/application'
import managersService from "../../../../../services/managers";
import applicationStatusService from "../../../../../services/application-status";
import filterService from '../../../../../services/filter';
import autoServicesService from '../../../../../services/services';
import '../../../../App.scss';
import './../ApplicationList.scss';
import { SelectButton } from 'primereact/selectbutton';
import { Paginator } from "primereact/paginator";
import ApplicationListDataTable from "../../common/ApplicationListDataTable";
import OkFilterManagers from "../../common/FilterManagers";
import OkFilterServices from "../../common/FilterServices";
import ApplicationListDashboard from "../../common/ApplicatonListDashboard";
import OkMultiSelect from "../../../../common/OkMultiSelect";
import ApplicationModel from "../../../../../models/application";

import 'primeflex/primeflex.css';
import { InputText } from "primereact/inputtext";
import OkFilterCalendar from "../../common/FilterCalendar";
import ErrorService from "../../../../../services/error";

@inject('filterStore', 'userStore', 'uiStore')
@observer
class ApplicationListDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            period: filterService.getCurrentMonth(),
            error: false,
            first: 1,
            isLoading: false,
            isLoadingTable: false,
            isLoadingDash: false,
            applications: [],
            dashboard: {},
            pager: {
                "size": 10,
                "number": 1,
                "pageCount": 0,
                "itemCount": 0
            }
        };

        this.filterStore = props.filterStore;
        this.userStore = props.userStore;
        this.uiStore = props.uiStore;

        this.managers = [
            { "value": 0, "label": "Все менеджеры" }
        ];

        this.services = [
            { "value": 0, "label": "Все филиалы" }
        ];

        this.statuses = [];

        this.periods = [
            { name: 'Сегодня', value: filterService.getToday() },
            { name: 'Вчера', value: filterService.getYesterday() },
            { name: 'Неделя', value: filterService.getCurrentWeek() },
            { name: 'Месяц', value: filterService.getCurrentMonth() }
        ];

        this.managerDisposer = reaction(
            () => this.filterStore.filter.service_id,
            service_id => {
                this.getManagers(service_id);
            }
        );

        this.disposer = reaction(
            () => this.filterStore.numberOfChanges,
            numberOfChanges => {
                this.getApplicationsList();
            }
        );

        this.dataTableDisposer = reaction(
            () => this.filterStore.numberOfChangesDatatable,
            numberOfChangesDatatable => {
                this.getOnlyApplicationsList();
            }
        );

    }

// номер записи с которой рендерить пагинацию (0, 10, 20...)
// first={this.state.first}
    getStartItemPager = () => {
        return this.filterStore.filter.page.size * (this.filterStore.filter.page.number - 1)
    }

    componentWillUnmount() {
        this.managerDisposer();
        this.disposer();
        this.dataTableDisposer();
    }

    componentDidMount() {
        this.setState({
            first: this.getStartItemPager(),
            isLoading: true,
        });

//TODO переделать цепочку в Promise.all
        autoServicesService.getServices().then(data => {
            const services = data.map((service) => ({
                value: service.id,
                label: service.title,
            }));

            this.services = [
                ...this.services,
                ...services
            ];

            return managersService.getManagers()
        }).then(data => {
            const managers = data.map((manager) => {
                return { "value": manager.id, "label": manager.fullName };
            });

            this.setDefaultManager(managers);

            this.managers = [
                ...this.managers,
                ...managers
            ];

            return applicationStatusService.getApplicationStatus();
        })
            .then(data => {
                const statuses = data.map((status) => {
                    return { "value": status.id, "label": status.title };
                });

                this.statuses = [
                    ...statuses
                ];

                return applicationService.getDashboard(this.filterStore.filter);
            })
            .then(data => {
                this.setState({
                    dashboard: data,
                });

                return applicationService.getApplicationsList(this.filterStore.filter);
            })
            .then((data) => {
                try {
                    this.setState({
                        isLoadingTable: false,
                        isLoading: false,
                        applications: data.applications.map(item => {
                            return new ApplicationModel(item);
                        }),
                        pager: data.pager
                    });

                }
                catch(e) {
                    throw(new ErrorService(e).getError("Внимание", ""));
                }

            })
            .catch(error => {
                error.error.data.map(item => {
                    this.uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });

            });
    }

    setDefaultManager = (managers) => {
        if(managers.length === 1 && this.filterStore.filter.manager_id === 0) {
            this.filterStore.setDefaultManager(managers[0].value);
        }
    }

    getManagers = (service_id) => {
        this.setState({
            isLoadingTable: true,
        });

        managersService.clear();
        managersService.getManagers(service_id).then(data=>{


            const managers = data.map((manager) => {
                return { "value": manager.id, "label": manager.fullName };
            });

            this.managers = [
                { "value": 0, "label": "Все менеджеры" },
                 ...managers
            ];

            this.setState({
                isLoadingTable: false,
            });

        })

    }

    getOnlyApplicationsList = () => {
        this.setState({
            isLoadingTable: true,
        });
        applicationService.getApplicationsList(this.filterStore.filter)
            .then(data => {

                this.setState({
                    isLoadingTable: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });
            })
    }

    getApplicationsList = () => {
        this.setState({
            isLoadingTable: true,
            isLoadingDash: true,
        });
        applicationService.getApplicationsList(this.filterStore.filter)
            .then(data => {

                this.setState({
                    isLoadingTable: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });

                return applicationService.getDashboard(this.filterStore.filter)
            })
            .then(data => {
                this.setState({
                    isLoadingDash: false,
                    dashboard: data
                });

            });
    }

    getDebounceApplicationsList = _.debounce(this.getOnlyApplicationsList, 1000);

    setKeyword = (e) => {
//TODO пока оставим так. потом найду как использовать юникод
        if (e.target.value.length === 0 || e.target.value.trim().match(/[\wа-яА-ЯёЁ\d]/) !== null) {
            this.filterStore.setFieldValue(e.target.name, e.target.value);
            this.getDebounceApplicationsList();
        }
    }

    setPage = (pageNum) => {
        pageNum++;
        this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: pageNum });
    }

    setPeriod = (e) => {
        if (e.target.value) {
            this.filterStore.setFieldValue('date', {
                'from': new Date(e.value[0]),
                'to': new Date(e.value[1])
            });
        }

        this.setState({
            period: e.value
        });
    }

    handleChangeStatuses = () => {
        this.getOnlyApplicationsList()
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.filterStore.setFieldValue(name, value);
    }

    handleClearPeriod = () => {
        this.setState({ period: false });
    }

    render() {
        const {
            isLoading,
            error,
            isLoadingTable,
            isLoadingDash,
            applications,
            dashboard,
        } = this.state;

        return (
            <React.Fragment>
                <div className={cn('page__application-list', 'page')}>
                    <div className={'application-list'}>
                        <React.Fragment>
                            <div className={'title'}>
                                <h1>Список заявок</h1>
                            </div>
                            {isLoading && <div className={cn('form-loading')} />}
                            <div className={cn('application-list__filter')}>
                                <div className={cn('filter__wrapper')}>
                                    <div className={cn('filter-content__filter')}>
                                        <div className={'text-field'}>
                                            <SelectButton
                                                options={this.periods}
                                                value={this.state.period}
                                                onChange={(e) => this.setPeriod(e)}
                                                optionLabel="name"
                                            />
                                        </div>
                                        <div className={'date-field'}>
                                            <OkFilterCalendar />
                                        </div>
                                        <div className={'text-field managers-field'}>
                                            <OkFilterServices
                                                services={this.services}
                                            />
                                        </div>
                                        <div className={'text-field managers-field'}>
                                            <OkFilterManagers
                                                managers={this.managers}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn('dashboard__wrapper')}>
                                    <div className={cn('dashboard', { dashboard__loading: isLoadingDash })}>
                                        <ApplicationListDashboard value={dashboard} />
                                    </div>
                                </div>
                            </div>
                            <div className={cn('application-list__table')}>
                                <div className={cn('application-list__wrapper')}>
                                    <div className={cn('application-list__search')}>
                                        <div>
                                            <div className={'text-field status-field'}>
                                                <label htmlFor="status_id">Статус</label>
                                                <OkMultiSelect
                                                    inputId="status_id"
                                                    name="status_id"
                                                    options={this.statuses}
                                                    value={this.filterStore.filter.status_id}
                                                    onChange={(e) => this.handleChange(e)}
                                                    onClose={() => {
                                                        this.handleChangeStatuses()
                                                    }}
                                                    selectedItemsLabel={'Выбрано статусов: {0}'}
                                                    placeholder={'Все статусы'}
                                                    optionLabel="label"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className={'text-field word-field'}>
                                                <label>&nbsp;</label>
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        id="query"
                                                        name="query"
                                                        value={this.filterStore.filter.query}
                                                        onChange={(e) => this.setKeyword(e)}
                                                        placeholder="Поиск по клиенту или автомобилю"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment>
                                        <ApplicationListDataTable
                                            applications={applications}
                                            isLoadingTable={isLoadingTable}
                                        />
                                        <Paginator
                                            rows={this.state.pager.size}
                                            totalRecords={this.state.pager.itemCount}
                                            first={this.state.first}
                                            onPageChange={(e) => {
                                                this.setPage(e.page)
                                                this.setState({ first: e.first })
                                            }}
                                        />
                                    </React.Fragment>
                                </div>
                            </div>

                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ApplicationListDesktop;
