import React, {Component} from "react";
import PromoBlockIco1 from "../../../images/promo_block_ico1.svg";
import cn from "classnames";
import Phone from "../../../images/phone.svg";
import Notebook from "../../../images/notebook.svg";
import Block3Ico1 from "../../../images/block3_ico1.svg";
import Block3Ico2 from "../../../images/block3_ico2.svg";
import Block3Ico3 from "../../../images/block3_ico3.svg";
import BlockRequest from "./BlockRequest";
import './Promo.scss';


class PromoPage extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={cn('page__index', 'page')}>
                    <div className={'block promo'}>
                        <div className={'promo__wrapper'}>
                            <img src={PromoBlockIco1} alt="" className={'promo__img1'}/>
                            <div className={'promo__content'}>
                                <h1 className={cn('promo__title', 'title')}>Привлекайте новых клиентов, собирайте
                                    обратную
                                    связь
                                    и получайте отзывы</h1>
                                <h5 className={cn('promo__subtitle', 'subtitle')}>Сервис по управлению репутацией</h5>
                            </div>
                        </div>
                    </div>

                    <div className={cn('block', 'block1')}>
                        <div className={'block__wrapper'}>
                            <div className={'block__bg-white'}>
                                <div className={cn('block__picture')}>
                                    <img src={Phone} alt=""/>
                                </div>
                                <div>
                                    <h1 className={cn('block__title', 'title')}>Ваши клиенты выбирают вас через Яндекс,
                                        Google,
                                        Avito или 2GIS</h1>
                                    <div className={cn('block__text', 'text')}>
                                        <p>Сегодня клиенты выбирают автосалон, ориентируясь на отзывы, рейтинги и
                                            рекомендации.</p>
                                        <p>А поисковые системы и сайты объявлений сортируют ваши объявления исходя из
                                            вашей
                                            репутации.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cn('block', 'block2')}>
                        <div className={'block__wrapper'}>
                            <div className={'block__bg-transparent'}>
                                <div className={'block__text-content'}>
                                    <h1 className={cn('block__title', 'title')}>Как мы вам поможем</h1>
                                    <div className={cn('block__text', 'text')}>
                                        Наш сервис позволит:
                                        <ul className={cn('block__list')}>
                                            <li>Получать больше отзывов от довольных клиентов</li>
                                            <li>Размещать их на онлайн-площадках</li>
                                            <li>Системно работать с обратной связью </li>
                                            <li>Мотивировать сотрудников</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={cn('block__picture')}>
                                    <img src={Notebook} alt="1" width={'100%'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cn('block', 'block3')}>
                        <div className={'block__wrapper'}>
                            <div>
                                <div className={'block__row'}>
                                    <div className={cn('block__col', 'col')}>
                                        <div className={'col__icon'}>
                                            <img src={Block3Ico1} alt="" width={'100%'} height={'167px'}/>
                                        </div>
                                        <div className={'col__title'}>Поможем улучшить репутацию</div>
                                        <div className={'col__text'}>Больше позитивных отзывов, системная работа с
                                            негативными
                                        </div>
                                    </div>
                                    <div className={cn('block__col', 'col')}>
                                        <div className={'col__icon'}>
                                            <img src={Block3Ico2} alt="" width={'100%'} height={'167px'}/>
                                        </div>
                                        <div className={'col__title'}>Удобный контроль и аналитика</div>
                                        <div className={'col__text'}>Вам будет легче отслеживать изменения рейтинга,
                                            контролировать и
                                            мотивировать сотрудников
                                        </div>
                                    </div>
                                    <div className={cn('block__col', 'col')}>
                                        <div className={cn('col__icon', 'col3')}>
                                            <img src={Block3Ico3} alt="" width={'100%'} height={'167px'}/>
                                        </div>
                                        <div className={'col__title'}>Сервис помогает вам экономить</div>
                                        <div className={'col__text'}>Мы берем большую часть работы по управлению
                                            репутацией
                                            вашего
                                            бизнеса на себя
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlockRequest
                        showForm={true}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default PromoPage;
