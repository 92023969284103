import userStore from './user';
import {filterStore, globalFilterStore} from './filter';
import uiStore from "./ui";
import applicationFormAddStore from "./ApplicationFormAdd";

// Сторы страниц
import reviewsStore from "./review";
import reviewFilterStore from "./reviewFilter";
import chartStore from "./chart";
import analyticsStore from './analytics';
import developersTagStore from './developersTag';

const stores = {
    userStore,
    filterStore,
    globalFilterStore,
    uiStore,
    applicationFormAddStore,
    reviewsStore,
    reviewFilterStore,
    chartStore,
    analyticsStore,
    developersTagStore,
};

export default stores;
