export default class ReviewAnswerParams {
    constructor(reviewAnswerParams) {
        this._count = reviewAnswerParams.count || 0;
        this._official = reviewAnswerParams.official || false;
        this._allowed = reviewAnswerParams.allowed || false;
    }

    /**
     * @return {int}
     */
    get count() {
        return this._count;
    }

    /**
     * @return {boolean}
     */
    get official() {
        return this._official;
    }

    /**
     * @return {boolean}
     */
    get allowed() {
        return this._allowed;
    }
}
