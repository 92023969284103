import React, { Component, forwardRef } from 'react';
import { Formik, useField, useFormikContext } from 'formik';
import * as Sentry from '@sentry/browser';
import cn from "classnames";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import applicationService from '../../../../services/application'
import autoServicesService from '../../../../services/services'
import okAnalyticsService from "../../../../services/okAnalytics";
import './ApplicationFormAdd.scss';
import '../../../common/ErrorPopup.scss';

import {
    ApplicationRequestModel,
    CarModel,
    CustomerModel,
    PurchaseModel,
} from "../../../../models/purchase";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-datepicker";
import OkInputMask from "../common/OkInputMask";

@inject('userStore', 'uiStore', 'applicationFormAddStore')
@observer
class ApplicationFormAdd extends Component {

    services = [];

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            success: false,
            isLoading: true,
            services: [],
            initForm: {
                autoServices: '',
                carBrand: '',
                carModel: '',
                carYear: '',
                carColor: '',
                carVin: '',
                customerLastname: '',
                customerFirstname: '',
                customerPhone: '',
                customerEmail: '',
                purchaseDate: ''
            },
            backErrors: [],
        };

        this.userStore = props.userStore;
        this.uiStore = props.uiStore;
        this.formStore = props.applicationFormAddStore;

        autoServicesService.getServices().then(data => {
            const services = data.map((service) => ({
                value: service.id,
                label: service.title,
            }));

            this.setState({ services: services });
            if (services.length === 1) {
                this.setState({
                    initForm: {
                        ...this.state.initForm,
                        autoServices: services[0].value,
                    }
                });
            }
            this.setState({ isLoading: false });
        })
    }

    componentDidMount() {
        okAnalyticsService.addEntity({
            page: 'application_add',
            target: 'page',
            event: 'open',
        });
    }

    reloadForm = (e) => {
        e.preventDefault();
        this.setState({
            success: false,
            error: false,
        });
    }

    /**
     * @param {string|boolean|[]} isError
     */
    errorClass = (isError) => {
        return (isError === true) ? 'p-error' : '';
    }

    /**
     * @param {*} obj
     * @param {string|number} prop
     */
    objToString(obj, prop) {
        return obj[prop];
    }

    /**
     * @param {Object} values
     */
//TODO уберу в form stores
    validateForm = (values) => {

        const errors = {};

        values.carBrand = values.carBrand.trim();
        values.carModel = values.carModel.trim();
        values.carColor = values.carColor.trim();
        values.customerLastname = values.customerLastname.trim();
        values.customerFirstname = values.customerFirstname.trim();
        values.customerEmail = values.customerEmail.trim();

        if (!values.autoServices) errors.autoServices = true;
        if (!values.carBrand) errors.carBrand = true;
        if (!values.carModel) errors.carModel = true;

        if (!values.carYear
            || isNaN(values.carYear)
            || (Number(values.carYear) < 1900 || Number(values.carYear) > new Date().getFullYear())
        ) {
            errors.carYear = 'От 1900 до ' + new Date().getFullYear();
        }

        if (!values.carColor) errors.carColor = true;

        if (!values.carVin
            || !/^[A-HJ-NPR-Z\d]{14}\d{3}$/i.test(values.carVin)
            || values.carVin.length !== 17) {
            errors.carVin = 'Некорректный формат';
        }

        if (values.customerEmail !== ''
            && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.customerEmail)) {
            errors.customerEmail = 'Некорректный формат';
        }

        if (!values.customerLastname) errors.customerLastname = true;
        if (!values.customerFirstname) errors.customerFirstname = true;
        if (!values.customerPhone) errors.customerPhone = true;
        if (!values.purchaseDate) errors.purchaseDate = true;

        return errors;
    }


    onFormSubmit = (values) => {
        okAnalyticsService.addEntity({
            page: 'application_add',
            target: 'application_add_form',
            event: 'send_application',
        });

        //TODO убрать в form stores
        const data = new ApplicationRequestModel({
            service_id: values.autoServices,
            purchase: new PurchaseModel({
                date: moment(values.purchaseDate).format("YYYY-MM-DD").toString(),
                car: new CarModel({
                    brand: values.carBrand,
                    model: values.carModel,
                    year: values.carYear,
                    color: values.carColor,
                    vin: values.carVin,
                })
            }),
            customer: new CustomerModel({
                    lastname: values.customerLastname,
                    firstname: values.customerFirstname,
                    phone: values.customerPhone,
                    email: values.customerEmail,
                },
            )
        }).toApiObject();

        //TODO тут запихиваем данные из формы (values) в form stores
        //TODO вызываем субмит из form stores

        this.setState({ isLoading: true });
        applicationService.saveApplication(data)
            .then(() => {
                this.setState({
                    success: true
                });
                return true;
            })
            .catch((error) => {
                error.error.data.map(item => {
                    this.uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });
                this.setState({ backErrors: error.error.data?.map(item => item.field) });


                Sentry.captureException(new Error(JSON.stringify(error)));
                this.setState({
                    error: true
                });
                return false
            }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    /**
     * @param {React.FormEvent<HTMLInputElement>} e
     * @param {number} maxLength
     * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue
     */
    removeSpaces = (e, maxLength, setFieldValue) => {
        setFieldValue(e.target.name, e.target.value.split(' ').join('').substr(0, maxLength), true);
    }

    render() {
        const {
            success,
            initForm,
            services,
            isLoading,
            backErrors
        } = this.state;

        return (
            <React.Fragment>
                <div className={cn('page__application', 'page')}>
                    {success ? <SuccessBlock reloadForm={this.reloadForm} /> :
                        <>
                            <div className={'title'}>
                                <h1>Заявка на отзыв</h1>
                            </div>
                            <div className={cn('form')}>
                                {isLoading && <div className={cn('form-loading')} />}
                                {<React.Fragment>

                                    <Formik
                                        enableReinitialize
                                        initialValues={initForm}
                                        validate={this.validateForm}
                                        backErrors={backErrors}
                                        onSubmit={(values) => {
                                            this.onFormSubmit(values);
                                        }}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              setFieldValue,
                                              handleSubmit,
                                              isSubmitting,
                                          }) => {
                                            return <form onSubmit={handleSubmit}>
                                                <div className={'text-field'} style={{ width: '100%' }}>
                                                    <div className="p-float-label">
                                                        <Dropdown
                                                            style={{ width: '100%' }}
                                                            id='autoServices'
                                                            type="text"
                                                            name="autoServices"
                                                            options={services}
                                                            className={this.errorClass((errors.autoServices && touched.autoServices))}
                                                            onChange={handleChange}
                                                            value={values.autoServices}
                                                        />
                                                        <label htmlFor='autoServices'>Филиал <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>

                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carBrand'
                                                            type="text"
                                                            name="carBrand"
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.brand') ||
                                                                    (errors.carBrand && touched.carBrand))
                                                            )
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carBrand}
                                                        />
                                                        <label htmlFor='carBrand'>Марка <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>

                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carModel'
                                                            type="text"
                                                            name='carModel'
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.model') ||
                                                                    (errors.carModel && touched.carModel))
                                                            )
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carModel}
                                                        />
                                                        <label htmlFor='carModel'>Модель<span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carYear'
                                                            name='carYear'
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.year') ||
                                                                    (errors.carYear && touched.carYear))
                                                            )
                                                            }
                                                            onChange={(e) => {
                                                                this.removeSpaces(e, 4, setFieldValue)
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.carYear}
                                                        />
                                                        {(errors.carYear && touched.carYear) &&
                                                            <span className={'inputTextError'}>{errors.carYear}</span>}
                                                        <label htmlFor='carYear'>Год <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carColor'
                                                            name='carColor'
                                                            type='text'
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.color') ||
                                                                    (errors.carColor && touched.carColor)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carColor}
                                                        />
                                                        <label htmlFor='carColor'>Цвет <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carVin'
                                                            name='carVin'
                                                            type="text"
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.vin') ||
                                                                    (errors.carVin && touched.carVin)
                                                                )
                                                            )}
                                                            onChange={(e) => {
                                                                this.removeSpaces(e, 17, setFieldValue)
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.carVin}
                                                        />
                                                        {(errors.carVin && touched.carVin) &&
                                                            <span className={'inputTextError'}>{errors.carVin}</span>}
                                                        <label htmlFor='carVin'>VIN <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <OkInputMask
                                                            mask="+7 (n99) 999-99-99"
                                                            id='customerPhone'
                                                            name='customerPhone'
                                                            type="text"
                                                            className={this.errorClass(
                                                                !!backErrors.find(item => item === 'customer.phone') ||
                                                                (errors.customerPhone && touched.customerPhone)
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.customerPhone}
                                                        />
                                                        <label htmlFor='customerPhone'>Телефон клиента <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='customerFirstname'
                                                            name='customerFirstname'
                                                            type="text"
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'customer.firstname') ||
                                                                    (errors.customerFirstname && touched.customerFirstname)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.customerFirstname}
                                                        />
                                                        <label htmlFor='customerFirstname'>Имя клиента <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='customerLastname'
                                                            name='customerLastname'
                                                            type="text"
                                                            className={this.errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'customer.lastname') ||
                                                                    (errors.customerLastname && touched.customerLastname)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.customerLastname}
                                                        />
                                                        <label htmlFor='customerLastname'>Фамилия клиента <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='customerEmail'
                                                            name='customerEmail'
                                                            type="text"
                                                            className={this.errorClass((errors.customerEmail && touched.customerEmail))}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.customerEmail}
                                                        />
                                                        <label htmlFor='customerEmail'>E-mail клиента</label>
                                                    </div>
                                                </div>

                                                <div className={'text-field'}>
                                                    <MyCalendar
                                                        id='purchaseDate'
                                                        name='purchaseDate'
                                                        type="text"
                                                        className={this.errorClass(
                                                            (
                                                                !!backErrors.find(item => item === 'purchase.date') ||
                                                                (errors.purchaseDate && touched.purchaseDate)
                                                            )
                                                        )}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.purchaseDate}
                                                    />

                                                    <div className="p-float-label">

                                                    </div>
                                                </div>
                                                <div className={cn('form-btn__wrapper')}>
                                                    <Button label="Отправить заявку"
                                                            type="submit"
                                                            data-tag='analytics_add_form'
                                                            className={cn('submit-btn')}
                                                    />
                                                </div>

                                            </form>
                                        }}
                                    </Formik>
                                </React.Fragment>}
                            </div>
                        </>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const MyCalendar = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    const ContainerCalendar = forwardRef(
        ({ value, onClick, className }, ref) => {
            return (
                <div className={cn("p-float-label", "p-input-icon-right")}>
                    <i className="pi pi-calendar" />
                    <InputText
                        readOnly
                        value={value}
                        onClick={onClick}
                        ref={ref}
                        className={className}
                    />

                    <label htmlFor="purchaseDate">
                        Дата сделки <span style={{ color: 'red' }}>*</span>
                    </label>
                </div>
            )
        }
    );

    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            maxDate={new Date()}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
            calendarClassName="okCalendar"
            locale="ru"
            dateFormat={'dd.MM.yyyy'}
            calendarStartDay={1}
            customInput={<ContainerCalendar />}
        />
    );
}

class SuccessBlock extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={cn('form', 'successForm')}>
                    <div className={'successText'}>Ваша заявка успешно отправлена!</div>
                    <div className={'btn__wrapper'}>
                        <Button label="Подать еще одну заявку" onClick={(e) => {
                            this.props.reloadForm(e)
                        }}
                        />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


export default ApplicationFormAdd;
