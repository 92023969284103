import React, { useEffect } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import _ from "underscore";

import Calendar from "../../../components/pages/review/filter/Calendar";
import { FilterButtonSimple, FilterButton } from '../../common/FilterButton';
import ToolTip from "../../common/ToolTip";
import SlideNav from "../analytics/SlideNav";
import { useIsMount } from "../../../hooks";
import './Filter.scss';

const TOOLTIP_TEXT = 'Фильтр периода применяется к показателю "Всего заявок за период". Значения остальных показателей в таблице формируются исходя из отфильтрованных заявок.'

const FilterApsBranch = inject('analyticsStore')(observer(
        ({ analyticsStore, loading, mobile = false }) => {
            const { branches, filterBranch, setFieldValue } = analyticsStore;
            const isMount = useIsMount();

            useEffect(() => {
                analyticsStore.getBranches();
            }, []);

            const setValue = _.debounce((field, data) => {
                if (!isMount) {
                    setFieldValue(field, data, 'filterBranch');
                }
            }, 800);

            const handlerChangeDate = (date) => {
                analyticsStore.setDate(date, 'filterBranch');
            }

            return (
                <>
                    <div className={cn('filter__wrapper')}>
                        <div className={'filter__item'}>
                            <Calendar
                                onChange={handlerChangeDate}
                                dateRange={filterBranch.date}
                                shortFormat={mobile}
                            />

                            <div className={'tooltip__wrapper'}>
                                <ToolTip mobile={mobile}>
                                    {TOOLTIP_TEXT}
                                </ToolTip>
                            </div>

                        </div>

                        <div className={'filter__item'}>
                            {branches.length > 0 && <FilterButtonSimple
                                name={branches[0]?.name}
                                list={branches}
                                onChange={(data) => setValue('branches', data)}
                                filter={filterBranch.branches}
                                loading={loading}
                                mobile={mobile}
                            />}
                        </div>
                    </div>
                </>
            )
        }
    )
);

const FilterReview = inject('analyticsStore')(observer(
    ({ analyticsStore, loading, mobile = false}) => {
        const { branches, scores, sources, filter, setFieldValue } = analyticsStore;
        const isMount = useIsMount();

        useEffect(() => {
            analyticsStore.getBranches();
            analyticsStore.getSources();
        }, [])

        const setValue = _.debounce((field, data) => {
            if (!isMount) {
                setFieldValue(field, data);
            }
        }, 800);

        const handlerChangeDate = (date) => {
            analyticsStore.setDate(date);
        }

        const clear = () => {
            analyticsStore.clearFilter();
        }

        return (
            <>
                <div className={cn('filter__wrapper')}>
                    <div className={'filter__item'}>
                        <Calendar
                            onChange={handlerChangeDate}
                            dateRange={filter.date}
                            shortFormat={mobile}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Филиалы'
                            list={branches}
                            onChange={(data) => setValue('branches', data)}
                            filter={filter.branches}
                            loading={loading}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Источник'
                            list={sources}
                            onChange={(data) => setValue('sources', data)}
                            filter={filter.sources}
                            loading={loading}
                        />
                    </div>
                    <div className={'filter__item'}>
                        <FilterButton
                            name='Оценки'
                            list={scores}
                            onChange={(data) => setValue('scores', data)}
                            filter={filter.scores}
                            loading={loading}
                        />
                    </div>
                    <div
                        className={'filter__clear-btn'}
                        onClick={clear}
                    >Очистить все
                    </div>
                </div>
            </>
        )
    }
));

const FilterAps = inject('analyticsStore', 'openHref')(observer(
        ({ analyticsStore, openHref, mobile = false }) => {
            const { filter } = analyticsStore;

            const handleChangeNav = (href) => {
                openHref(href)
            }

            const handlerChangeDate = (date) => {
                analyticsStore.setDate(date);
            }

            return (
                <>
                    <div className={cn('filter__wrapper')}>
                        <div className={'filter__item'}>
                            <SlideNav
                                handleChangeNav={handleChangeNav}
                                data={[
                                    {'branches': 'Филиалы'},
                                    {'managers': 'Сотрудники'}
                                ]}
                            />
                        </div>

                        <div className={'filter__item'}>
                            <Calendar
                                onChange={handlerChangeDate}
                                dateRange={filter.date}
                                shortFormat={mobile}
                            />
                            <div className={'tooltip__wrapper'}>
                                <ToolTip
                                    mobile={mobile}
                                >
                                    {TOOLTIP_TEXT}
                                </ToolTip>
                            </div>
                        </div>

                    </div>
                </>
            )
        }
    )
);


export {
    FilterReview,
    FilterAps,
    FilterApsBranch,
};
