import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import okAnalyticsService from "../../../../services/okAnalytics";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { pluralize } from "../../../../helpers";
import BranchesBlock from "./common/BranchesBlock";
import AnswerList from "./AnswerList";
import AnswerAdd from "./common/AnswerAdd";
import SourceBlock from "./common/SourceBlock";
import AnswerButton from "./common/AnswerButton";
import ClaimsSlideBar from "./common/ClaimsSlideBar";
import ClaimStatus from "./common/ClaimStatus";

/**
 * @param {ExternalReview} data
 * @param {ReviewsStore} reviewsStore
 * @return {JSX.Element}
 * @constructor
 */
const ReviewItem = ({ data, reviewsStore }) => {

    const [showQuestionsListBlock, setShowQuestionsListBlock] = useState(false);
    const [showAnswerBlock, setShowAnswerBlock] = useState(false);
    const [answerList, setAnswerList] = useState([]);
    const [review, setReview] = useState(data);
    const [visibleRight, setVisibleRight] = useState(false);

    const [toggle, setToggle] = useState(true);

    const [answer, setAnswer] = useState({});
    const [claimsAllowed, setClaimsAllowed] = useState(false);
    const [claimStatus, setClaimStatus] = useState('');


    useEffect(() => {
        setAnswer(getText());
        setClaimsAllowed(data.claim.allowed);
        setClaimStatus(data.claim.status);
    }, []);

    useEffect(() => {
        if (showQuestionsListBlock === true) {
            reviewsStore.loadAnswers(data.id)
                .then((result) => {
                    setAnswerList(result);
                });
        }
    }, [showQuestionsListBlock]);

    const getText = () => {
        const textArr = review.text.replaceAll(/\n\n+/g, "\n").split('\n');

        const text = textArr.map((item, key) => {
            return <span key={key}>{item}<br /></span>
        });
        const showShadow = !!(textArr.length > 4 || review.text.length > 350);

        return {
            text,
            showShadow,
            log: `строк ${textArr.length}. символов ${review.text.length}.`
        }
    }

    const addAnswer = (data) => {
        reviewsStore.loadReview(data.reviewId)
            .then((result) => {
                setReview(result)
            });

        const newComment = reviewsStore.addNewCommentInList(data.id, data.reviewId, data.text);
        setAnswerList(answerList => [...answerList, newComment]);
        setShowQuestionsListBlock(true);
        setShowAnswerBlock(false);

        okAnalyticsService.addEntity({
            page: 'review_list',
            target: 'answer_form',
            event: 'send_answer',
        });

    }

    return (
        <div className={cn('review-list__item', 'item')}>
            <div className={cn('item__wrapper')}>
                <div className={cn('item__left')}>
                    <SourceBlock
                        item={review}
                    />
                    <Rating
                        className={cn('rating', 'no-cursor')}
                        value={review.score}
                        cancel={false}
                    />
                </div>
                <div className={cn('item__right')}>
                    <div className={cn('item__body-header')}>
                        <div className={cn('item__author')}>
                            <span>{!review.author.anonymous && review.author.name}</span>
                            <br />
                            {review.postedAt}
                        </div>
                        <div className={cn('item__status')}>
                            <BranchesBlock
                                branches={review.branches}
                                isShort={review.isShortBranchesBlock}
                            />
                            {/* TODO Убрать в отдельный компонент */}
                            {review.deletedAt && <div className={cn('status', 'type5')}>Снят с публикации</div>}
                            {review.published && !review.deletedAt && <div className={cn('status', 'type1')}>Опубликован</div>}
                            {!review.published && !review.deletedAt && <div className={cn('status', 'type2')}>Не опубликован</div>}
                            {review.type === 'application' && <div className={cn('status', 'type3')}>OKR заявка</div>}
                            {!review.answer.official && <div className={cn('status', 'type4')}>Без оф. ответа</div>}

                        </div>
                    </div>
                    <div className={cn('item__body', { 'hide': (toggle && answer.showShadow) })}>
                        {answer.text}
                        <div
                            className={cn('shadow')}
                            onClick={() => {
                                setToggle(false);
                            }}
                        />
                    </div>
                    <div className={cn('show-more-btn')}>
                        {(toggle && answer.showShadow) && <>
                            <a
                                href="#"
                                onClick={(e) => {
                                    setToggle(false);
                                    return e.preventDefault();
                                }}
                            >Показать больше</a>
                        </>}
                    </div>
                    <div className={cn('replay-toggle-btn')}>
                        {/*TODO убрать в компонент. */}
                        <div className={cn('replay-toggle-btn__wrapper')}>
                            {review.answer.count > 0 && <>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        setShowQuestionsListBlock((showQuestionsListBlock) => !showQuestionsListBlock);
                                        return e.preventDefault();
                                    }}
                                >
                                    {!showQuestionsListBlock && <>
                                        <span className={cn('badge')}>{review.answer.count}</span>
                                        {pluralize(review.answer.count, ['ответ', 'ответа', 'ответов'], false)}
                                        {
                                            !review.answer.official
                                                ?
                                                ' (нет официальных)'
                                                :
                                                ` (${pluralize(review.answer.official, ['официальный', 'официальных', 'официальных'])})`}
                                    </>}
                                    {showQuestionsListBlock && <>
                                        {review.answer.count > 1 && 'Скрыть ответы'}
                                        {review.answer.count < 2 && 'Скрыть ответ'}
                                    </>}
                                </a>
                            </>}
                            <span className={cn('no-answer')}>{!review.answer.count && 'Нет ответов'}</span>
                        </div>

                    </div>

                    {showQuestionsListBlock && <>
                        <AnswerList
                            items={answerList}
                        />
                    </>}

                    {showAnswerBlock && <>
                        <AnswerAdd
                            reviewId={review.id}
                            addAnswer={addAnswer}
                        />
                    </>}

                </div>
            </div>
            <div className={cn('item__footer')}>
                <div className={cn('item__footer-left')}>
                    <AnswerButton
                        item={review}
                        setShowAnswerBlock={setShowAnswerBlock}
                    />
                </div>

                <div className={cn('item__footer-right')}>
                    {claimsAllowed ? <>
                            <Button
                                label='Обжаловать отзыв'
                                className={cn('p-button-outlined')}
                                onClick={() => setVisibleRight(true)}
                            />

                            <ClaimsSlideBar
                                reviewId={review.id}
                                setClaimsAllowed={setClaimsAllowed}
                                setClaimStatus={setClaimStatus}
                                visibleRight={visibleRight}
                                setVisibleRight={setVisibleRight}
                            />
                        </>
                        : <ClaimStatus status={claimStatus}/>}
                </div>


            </div>
        </div>
    );
}


export default inject('reviewsStore')(observer(ReviewItem));
