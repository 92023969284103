import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterApsBranch } from "../Filter";
import PrepareNumber from "../../../common/PrepareNumber";
import { inject, observer } from "mobx-react";
import { getDataByPlatform } from "../../../../helpers/index";


const BranchDesktop = ({ analyticsStore }) => {
    const { filterBranch } = analyticsStore;
    const [reviews, setReviews] = useState({ items: [] });
    const [appls, setAppls] = useState({ items: [] });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        Promise.all([
            analyticsStore.getReviewsByPlatformList(),
            analyticsStore.getApsManagerByPlatformList(),
        ]).then(([reviews, appls]) => {
            setReviews(reviews);
            setAppls(appls);
            setLoading(false);
        });
    }, [JSON.stringify(filterBranch)]);

    return (
        <>
            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs />
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterApsBranch loading={loading} />
                </div>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('analytics-table__header')}>
                        <h3>Отзывы</h3>
                    </div>

                    <div className={cn('table__wrapper', 'branch')}>
                        {loading && <div className={'loader'} />}
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Источник</th>
                                        <th className={cn('rating')}>Рейтинг</th>
                                        <th className={cn('scores', 'align__right')}>Оценки</th>
                                        <th className={cn('align__left')}>&nbsp;</th>
                                        <th className={cn('review', 'align__right')}>Отзывы</th>
                                        <th className={cn('align__left')}>&nbsp;</th>
                                        <th className={cn('align__right', 'answer')}>С ответом</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reviews?.total && <BranchFirstRow data={reviews?.total} />}
                                    {reviews.items.length > 0 && reviews.items.map((item, key) => (
                                        <BranchRow
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cn('analytics-table', 'application-table', 'table')}>
                    <div className={cn('analytics-table__header')}>
                        <h3>Заявки</h3>
                    </div>
                    <div className={cn('table__wrapper', 'branch')}>
                        {loading && <div className={'loader'} />}
                        <div className={cn("p-datatable", "p-component", "wide")}>
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Сотрудник</th>
                                        <th className={cn('align__right')}>Всего заявок<br />за период</th>
                                        <th className={cn('align__right')}>Заявки<br />в работе</th>
                                        <th className={cn('align__right')}>Отзыв<br />не получен<br />/ ошибка</th>
                                        <th className={cn('align__right')}>Всего<br />получено<br />отзывов</th>
                                        <th className={cn('align__right')}>Конверсия</th>
                                        <th className={cn('align__right')}>Получено<br />поз. отзывов</th>
                                        <th className={cn('align__right')}>Перехвачено<br />нег. отзывов</th>
                                        <th className={cn('align__right')}>NPS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {appls?.total && <ApplFirstRow data={appls?.total} />}
                                    {appls.items.length > 0 && appls.items.map((item, key) => (
                                        <ApplRow
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const BranchFirstRow = ({ data }) => {
    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div>Все источники</div>
                </div>
            </td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')} />
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <span className={cn(
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber number={data.diff.rating} />
                    </span>
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.scores) >= 0 },
                    { 'diff__down': Number(data.diff.scores) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.scores} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.reviews) >= 0 },
                    { 'diff__down': Number(data.diff.reviews) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.reviews} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {data.close.reviews ? ((data.close.responded / data.close.reviews) * 100).toFixed() : <>0</>}%
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const BranchRow = ({ data }) => {
    const { name, img } = getDataByPlatform(data.adplatform.code);

    return (
        <tr>
            <td>
                <div className={cn('name')}>
                    <a href={data.adplatform.url} target="_blank">
                        <img src={img} alt="" width={20} height={20} title={data.adplatform.title} />
                    </a>
                    <div>{name}</div>
                </div>
            </td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')} />
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <span className={cn(
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber number={data.diff.rating} />
                    </span>
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.scores) >= 0 },
                    { 'diff__down': Number(data.diff.scores) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.scores} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.reviews) >= 0 },
                    { 'diff__down': Number(data.diff.reviews) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.reviews} />
                </span>
            </td>
            <td className={cn('align__right')}>

                {((data.close.responded / data.close.reviews) * 100).toFixed()}%
            </td>
        </tr>
    );
    /*
        return (
            <>
                <tr>
                    <td>
                        <div className={cn('name')}>
                            <img src={YandexIco} alt="" width={20} height={20} />
                            <div>Яндекс</div>
                        </div>
                    </td>
                    <td>
                        <span className={cn('rating')}>
                            <span className={cn('pi-star pi')} />
                            <span className={cn('value')}>
                                4,2
                            </span>
                            <span className={cn(
                                'diff',
                                'diff__up')}
                            >
                                <PrepareNumber number={23} />
                            </span>
                        </span>
                    </td>
                    <td className={cn('align__right')}>
                        14 565
                    </td>
                    <td>
                        <span className={cn(
                            'diff',
                            'diff__up')}
                        >
                            <PrepareNumber number={1000} />
                        </span>
                    </td>
                    <td className={cn('align__right')}>
                        12 134
                    </td>
                    <td>
                        <span className={cn(
                            'diff',
                            'diff__down')}
                        >
                            <PrepareNumber number={-1000} />
                        </span>
                    </td>
                    <td className={cn('align__right')}>
                        20%
                    </td>
                </tr>
            </>
        );
    */

};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const ApplFirstRow = ({ data }) => {
    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div>Все сотрудники</div>
                </div>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.applications.total}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.applications.progress}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.applications.error}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.reviews.total}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.rates.conversion}`).toLocaleString('ru-RU')}%
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.reviews.good}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.reviews.bad}`).toLocaleString('ru-RU')}
            </td>
            <td className={cn('align__right')}>
                {data.rates.nps}
            </td>
        </tr>
    );
}

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const ApplRow = ({ data }) => {
    return (
        <>
            <tr>
                <td>
                    <div className={cn('name')}>
                        <div className={cn(
                            'ico',
                            { 'ico__bag': data.branch },
                            { 'ico__user': data.manager }
                        )}
                        />
                        <div title={data.branch?.title || data.manager?.name}>{data.branch?.title || data.manager?.name}</div>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.applications.total}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.applications.progress}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.applications.error}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.reviews.total}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.rates.conversion}`).toLocaleString('ru-RU')}%
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.reviews.good}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {Number(`${data.reviews.bad}`).toLocaleString('ru-RU')}
                </td>
                <td className={cn('align__right')}>
                    {data.rates.nps}
                </td>
            </tr>

            {/*            <tr>
                <td>
                    <div className={cn('name')}>
                        <div className={cn(
                            'ico',
                            'ico__user'
                        )}
                        />
                        <div>Самсонов Евгений Николаевич</div>
                    </div>
                </td>
                <td className={cn('align__right')}>
                    11 023
                </td>
                <td className={cn('align__right')}>
                    1 000
                </td>
                <td className={cn('align__right')}>
                    1 000
                </td>
                <td className={cn('align__right')}>
                    678
                </td>
                <td className={cn('align__right')}>
                    7%
                </td>
                <td className={cn('align__right')}>
                    1 000
                </td>
                <td className={cn('align__right')}>
                    1 000
                </td>
                <td className={cn('align__right')}>
                    10
                </td>
            </tr>*/}
        </>
    );
};


export default inject('analyticsStore')(observer(BranchDesktop));
