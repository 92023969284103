import React, { Component } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";

import RatingField from "./RatingField";
import NpsBlock from "./NpsBlock";
import './ApplicationListDataTable.scss';
import { useHistory } from "react-router";


@inject('filterStore')
@observer
class ApplicationListDataTable extends Component {

    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
        this.state = {
            applications: this.props.applications,
            isLoadingTable: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.applications !== prevProps.applications) {
            this.setState({
                applications: this.props.applications
            });
        }
        if (this.props.isLoadingTable !== prevProps.isLoadingTable) {
            this.setState({
                isLoadingTable: this.props.isLoadingTable
            });
        }

    }

    render() {
        const {
            applications,
            isLoadingTable
        } = this.state;

        return (
            <div className="p-datatable p-component">
                <div className="p-datatable-wrapper">
                    <table role="grid">
                        <thead className="p-datatable-thead">
                        <tr>
                            <Column
                                className={cn('date-col')}
                                field="time"
                                header="Дата заявки"
                                sortable
                            />
                            <Column
                                className={cn('numApp-col')}
                                field="number"
                                header="Номер"
                                sortable
                            />
                            <Column
                                className={cn('status-col')}
                                field="status"
                                header="Статус"
                                sortable
                            />
                            <Column
                                className={cn('rating-col')}
                                field="rating"
                                header="Оценка"
                                sortable
                            />
                            <Column
                                className={cn('nps-col')}
                                field="nps"
                                header="NPS"
                                sortable
                            />
                            <Column
                                className={cn('manager-col')}
                                field="manager"
                                header="Менеджер"
                                sortable
                            />
                            <Column
                                className={cn('client-col')}
                                field="customer"
                                header="Клиент"
                                sortable
                            />
                            <Column
                                className={cn('auto-col')}
                                field="car"
                                header="Автомобиль"
                                sortable
                            />
                        </tr>
                        </thead>
                        {applications.length > 0 &&
                        <tbody className={cn('p-datatable-tbody', { application__loading: isLoadingTable })}>
                        {applications.map((item, key) => {
                            return (
                                <Row item={item} key={key} />
                            )
                        })}
                        </tbody>}
                    </table>
                </div>
            </div>
        );
    }
}

function Row(props) {
    const { item } = props;
    const history = useHistory();
    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/Application/detail/${item.id}`);
    }

    const [data, time] = item.time.replaceAll(/\s/g, "\n").split('\n');

    return (
        <tr className="p-datatable-row"
            onClick={handleClick}>
            <td className="date-col">
                <div>{data}</div>
                <div>{time}</div>
            </td>
            <td className="numApp-col">
                <div>{item.number}</div>
            </td>
            <td className="status-col">
                <span className="p-tag application-tag" style={
                    {
                        color: item.status.foreground,
                        backgroundColor: item.status.background
                    }
                }
                >
                    {item.status.title}
                </span>
            </td>
            <td className="rating-col">
                <RatingField
                    review={item.review}
                />
            </td>
            <td className={cn('rating-col')}>

                <NpsBlock
                    nps={item.nps}
                />
            </td>
            <td className="manager-col">
                <div>{item.manager.lastname}</div>
                <div>{item.manager.firstname}</div>
            </td>
            <td className="client-col">
                <div>{item.customer.lastname}</div>
                <div>{item.customer.firstname}</div>
            </td>
            <td className="auto-col">
                <div>
                    <span>{item.purchase.car.brand} {item.purchase.car.model}</span><span>,&nbsp;{item.purchase.car.year}</span>
                </div>
                <div>{item.purchase.car.vin}</div>
            </td>
        </tr>
    )
}

@inject('filterStore')
@observer
class Column extends Component {

    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
        this.defaultSort = 'pi-sort-alt'
    }

    render() {
        const { className, field, header, sortable } = this.props;

        return (
            <React.Fragment>
                {sortable ?
                    <th className={cn('p-sortable-column', className)} aria-sort="none" onClick={() => {

                        const newSortOrder = (this.filterStore.filter.sort.order === 'pi-sort-alt') ? 'asc' : (this.filterStore.filter.sort.order === 'asc') ? 'desc' : 'asc';

                        this.filterStore.setFieldValue('sort', {
                            column: field, order: newSortOrder
                        });

                    }}
                    >
                        <span className="p-column-title">{header}</span>
                        <span
                            className={cn("p-sortable-column-icon", "pi", "pi-fw", (field === this.filterStore.filter.sort.column) ?
                                this.filterStore.filter.sort.order :
                                this.defaultSort
                            )}
                        />

                    </th>
                    :
                    <th className={cn('p-sortable-column', className)} aria-sort="none">
                        <span className="p-column-title">{header}</span>
                    </th>
                }
            </React.Fragment>

        )
    }
}

export default ApplicationListDataTable;
