import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { inject, observer } from "mobx-react";
import { useIsMount } from "../../../../hooks/index";

import RatingBlock from "./RatingBlock";
import { pluralize } from "../../../../helpers/index";

const Rating = ({ chartStore, reviewFilterStore }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const isMount = useIsMount();
    const chartsFilter = reviewFilterStore.parseFilterToChart();
    let x = null;

    const [summary, setSummary] = useState({ rating: 0, diff: 0 });
    const [isLoader, setLoader] = useState(true);

    const initChart = (data = []) => {
        x = am4core.create("chart-source2", am4charts.XYChart);
        x.padding(0, 0, 25, 0);

        let dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.tooltip.disabled = true;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.LineSeries());

        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{tooltipDate}\nОценка: {value}"
        series.strokeWidth = 2;
        series.fill = am4core.color("#0FAFE2");
        series.tensionX = 0.8;
        series.fillOpacity = 0.5;


        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        series.segments.template.fillModifier = fillModifier;

// Make a panning cursor
        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "none";
        x.cursor.xAxis = dateAxis;
        x.cursor.snapToSeries = series;
        x.cursor.lineY.disabled = true;
        x.cursor.lineX.disabled = true;


// Make bullets grow on hover
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 1;
        bullet.circle.radius = 2;
        bullet.circle.fill = am4core.color("#fff");

        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;
        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'rating')
            .then((data) => {
                setSummary(data.summary);
                initChart(data.data);
                setLoader(false);
            });
        return () => destroyChart();
    }, []);

    useEffect(() => {
        if(!isMount) {
            chartStore.getData(chartsFilter, 'rating')
                .then((data) => {
                    setSummary(data.summary);
                    initChart(data.data);
                });
        }
    }, [JSON.stringify(chartsFilter)])


    return (
        <fieldset>
            <legend>Рейтинг</legend>
            {!isLoader && <div className={cn('chart__header', 'header')}>
                <div className={'header__left-side'}>
                    <div className={'number-top'}>
                        <div>{summary.total}</div>
                        <div className={cn('star')}>
                            <div className={cn('pi-star', 'pi')}/>
                        </div>

                    </div>

                    <div className={cn('number-bottom')}>
                        <RatingBlock
                            rating={summary.rating}
                        />
                    </div>
                </div>
                <div className={'header__right-side'}>
                    <div>{summary.total_rating.toLocaleString('ru-RU')} {pluralize(summary.total_rating, ['оценка', 'оценки', 'оценок'], false)}</div>
                    <div>{summary.total_reviews.toLocaleString('ru-RU')} {pluralize(summary.total_reviews, ['отзыв', 'отзыва', 'отзывов'], false)}</div>
                </div>
            </div>}
            <div className={cn('chart__body')}>
                <div id='chart-source2' style={{ width: '100%', height: '100%' }} />
            </div>
        </fieldset>
    );
}

export default inject('chartStore', 'reviewFilterStore')(observer(Rating));
