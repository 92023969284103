import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import "./ToolTip.scss";

/**
 *
 * @param children
 * @param {boolean} mobile
 * @returns {JSX.Element}
 * @constructor
 */
const ToolTip = ({children, mobile = false}) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const buttonRef = useRef();

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const handleClick = () => {
        setOpenDropdown(openDropdown => !openDropdown);
    }

    return (
        <div className={cn('tooltip', {'mobile': mobile})}>
            <i
                className="pi pi-exclamation-circle"
                onClick={handleClick}
                ref={buttonRef}
            />
            <div className={cn('placeholder', {'active': openDropdown})}>
                <div className={cn('triangle')}>
                    <div className={cn('triangle-with-shadow')}/>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ToolTip;
