import authService from "./auth";
import api from "./api";

class ProfileService {
    getProfile = () => {
        return authService.get(
            '/auth/profile',
        ).then((result) => {
            return  result;
        });
    }
    getProfile2 = () => {
        return api.get(
            '/auth/profile',
        ).then((result) => {
            return  result;
        });
    }

}

const profileService = new ProfileService();
export default profileService;
