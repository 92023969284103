import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {Dropdown} from "primereact/dropdown";

@inject('filterStore')
@observer
class OkFilterManagers extends Component {
    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.filterStore.setFieldValue(name, value);
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <label htmlFor="manager">Менеджер</label>
                    <Dropdown inputId="manager_id"
                              name="manager_id"
                              options={this.props.managers}
                              value={this.filterStore.filter.manager_id}
                              onChange={(e) => this.handleChange(e)}
                              placeholder={'Все менеджеры'}
                              filter
                              optionLabel="label"/>
                </React.Fragment>
            </div>
        )
    }
}
export default OkFilterManagers;
