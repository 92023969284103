import {AD_PLATFORM_TYPE} from "../models/ad-platform";
import YandexLogo from "../images/platforms/yandex_logo.png";
import AvitoLogo from "../images/platforms/avito_logo.png";
import DvagisLogo from "../images/platforms/2gis_logo.png";
import OkreviewLogo from "../images/platforms/okreview_logo.png";
import FlampLogo from "../images/platforms/flamp_logo.png";

import Yandex from "../images/icons/01.png";
import Avito from "../images/icons/03.png";
import DGis from "../images/icons/02.png";
import Irecommend from "../images/icons/04.png";
import Flamp from "../images/icons/06.png";
import Otzovik from "../images/icons/05.png";
import Zoon from "../images/icons/07.png";

/**
 * Plural forms for russian words
 *
 * @param {int} count quantity for word
 * @param {Array} words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @param {boolean} addCount
 * @return {String} Count + plural form for word
 * @author https://github.com/znechai
 */
const pluralize = (count, words, addCount = true) => {
    const cases = [2, 0, 1, 1, 1, 2];

    return (addCount ? count + ' ' : '') + words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
};

const serialize = function(obj, prefix) {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export {
    pluralize,
    serialize
}

/**
 * @param {String} type
 */
export const getLogoByPlatform = (type) => {
    switch (type) {
        case AD_PLATFORM_TYPE.YANDEX:
            return YandexLogo;

        case AD_PLATFORM_TYPE.AVITO:
            return AvitoLogo;

        case AD_PLATFORM_TYPE.DGIS:
            return DvagisLogo;

        case AD_PLATFORM_TYPE.FLAMP:
            return FlampLogo;

        case AD_PLATFORM_TYPE.ZOON:
            return Zoon;

        default:
            return OkreviewLogo;
    }
};


/**
 *
 * @param {string} type
 * @returns {{img, name: string}}
 */
export const getDataByPlatform = (type) => {
    switch (type) {
        case AD_PLATFORM_TYPE.YANDEX:
            return {
                name: 'Yandex',
                img: Yandex,
            };
        case AD_PLATFORM_TYPE.AVITO:
            return {
                name: 'Avito',
                img: Avito,
            };
        case AD_PLATFORM_TYPE.DGIS:
            return {
                name: '2Gis',
                img: DGis,
            };
        case AD_PLATFORM_TYPE.IRECOMMEND:
            return {
                name: 'irecommend',
                img: Irecommend,
            };
        case AD_PLATFORM_TYPE.OTZOVIK:
            return {
                name: 'irecommend',
                img: Irecommend,
            };
        case AD_PLATFORM_TYPE.FLAMP:
            return {
                name: 'Flamp',
                img: Flamp,
            };

        case AD_PLATFORM_TYPE.ZOON:
            return {
                name: 'Zoon',
                img: Zoon,
            };

        default:
            return {
                name: 'OkReview',
                img: OkreviewLogo,
            };
    }
};

export const jsonToFormUrl = (data) => {
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
}

