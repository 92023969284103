import React, { useEffect, useRef } from "react";
import cn from "classnames";
import './SlideNav.scss';

/**
 *
 * @param handleChangeNav
 * @param {Array} data [{'branches': 'Филиалы'},{'managers': 'Сотрудники'}]
 * @returns {JSX.Element}
 * @constructor
 */
const SlideNav = ({ handleChangeNav, data= [] }) => {
    const nav = useRef();
    const slide = useRef();

    useEffect(() => {
        const { offsetWidth, clientHeight, offsetLeft } = nav.current.childNodes[0];

        slide.current.style.width = (offsetWidth + 20) + 'px';
        slide.current.style.height = clientHeight + 'px';
        slide.current.style.left = (offsetLeft - 20) + 'px';
    }, []);

    const handleClick = (e, href) => {
        const { clientWidth, clientHeight, offsetLeft } = e.currentTarget;

        nav.current.childNodes.forEach(item => {
            if (item.className === 'active') item.className = '';
        })

        e.currentTarget.className = "active";

        slide.current.style.width = (clientWidth + 20) + 'px';
        slide.current.style.height = clientHeight + 'px';
        slide.current.style.left = (offsetLeft - 20) + 'px';
        handleChangeNav(href);
    }

    return (
        <div className={cn('filter-slider')}>
            <ul className="nav" ref={nav}>
                {data.map((item, index) => (
                    Object.keys(item).map((objId) => (
                        <li
                            className={cn({'active': index === 0})}
                            key={objId}
                            onClick={(e) => handleClick(e, objId)}
                        >
                            <a>{item[objId]}</a>
                        </li>
                    ))
                ))}
                <li className="slide1" ref={slide} />
            </ul>
        </div>
    )
}


export default SlideNav;
