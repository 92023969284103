import {MultiSelect} from "primereact/multiselect";

class OkMultiSelect extends MultiSelect {

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose({
                close: true
            })
        }
    }

    hide() {
        this.onClose();
        super.setState({overlayVisible: false});
    }

}

export default OkMultiSelect;