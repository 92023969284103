import authService from '../services/auth';
import api from './api';
import ExternalReview from "../models/external-review";
import {serialize} from '../helpers';

class ReviewService {
    /**
     * @param filter
     * @return {Promise<{pager: {}, sorting: {}, items: Array<ExternalReview>}>}
     */
    getList(filter = {}) {
        const queryString = serialize(filter);

        return api
            .get(`/extreview/reviews?${queryString}`)
            .then((data) => {
                return {
                    items: data.items.map(item => new ExternalReview(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            });
    }

    getItem(id) {
        return api
            .get(`/extreview/reviews/${id}`)
            .then((data) => new ExternalReview(data))
            .catch(err => err);

    }

    getCounters(filter) {
        const queryString = serialize(filter);
        return api
            .get(`/extreview/reviews/counters?${queryString}`)
            .then((data) => {
                return data;
            });
    }

    getBranch() {
        return api
            .get(`/extreview/branches`)
            .then((data) => {
                return data;
            });
    }

    getSources() {
        return api
            .get(`/extreview/integrations`)
            .then((data) => {
//FIXME пока такой костыль.
                for (const item in data.items) {
                    data.items[item].title === '2ГИС' && (data.items[item].title = `${data.items[item].title} + Flamp`);
                }
                return data;
            });
    }
}

const reviewService = new ReviewService();
export default reviewService;
