import moment from "moment";
import AdPlatform from "./ad-platform";
import Branch from "./branch";
import Author from "./author";
import Claim from "./claim";
import ReviewAnswerParams from "./review-answer-params";

/**
 * @typedef {string} ExternalReviewType
 **/

/**
 * @readonly
 * @enum {ExternalReviewType}
 */
export const EXTERNAL_REVIEW_TYPE = {
    REVIEW: 'review',
    APPLICATION: 'application',
};
/**
 *
 */
export default class ExternalReview {
    constructor(review) {
        this._id  = review.id || null;
        this._type  = review.type || null;
        this._adplatform = (review.adplatform) ? new AdPlatform(review.adplatform) : {};
        this._branches = (review.branches) ? review.branches.map(b => new Branch(b)) : [];
        this._author = (review.author) ? new Author(review.author) : {};
        this._score = review.score || null;
        this._text = review.text || null;
        this._attaches = review.attaches || [];
        this._answer = (review.answer) ? new ReviewAnswerParams(review.answer) : {};
        this._claim = (review.claim) ? new Claim(review.claim) : {};
        this._published = review.published || false;
        this._postedAt = review.postedAt || null;
        this._updatedAt = review.updatedAt || null;
        this._deletedAt = review.deletedAt || null;


        this._url = review.url || null;
        this._subsourceCode = review.subsource_code || null;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {ExternalReviewType|null}
     */
    get type() {
        return this._type;
    }

    /**
     * @return {AdPlatform}
     */
    get adplatform() {
        return this._adplatform;
    }

    /**
     * @return {Array<Branch>}
     */
    get branches() {
        return this._branches;
    }

    /**
     * @return {Author}
     */
    get author() {
        return this._author;
    }

    /**
     * @return {int|null}
     */
    get score() {
        return this._score;
    }

    /**
     * @return {string|null}
     */
    get text() {
        return this._text;
    }

    /**
     * @return {Array<string>}
     */
    get attaches() {
        return this._attaches;
    }

    /**
     * @return {ReviewAnswerParams}
     */
    get answer() {
        return this._answer;
    }

    /**
     *
     * @returns {Claim|{}}
     */
    get claim() {
        return this._claim;
    }


    /**
     * @return {boolean}
     */
    get published() {
        return this._published;
    }

    /**
     * @return {string}
     */
    get postedAt() {
        return this._postedAt ? moment(this._postedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this._updatedAt ? moment(this._updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|null}
     */
    get deletedAt() {
        return this._deletedAt;
    }



    /**
     * @returns {string|null}
     */
    get url() {
// если нет основного url показываем url альтернативной платформы
        let url = (this._url) ?  this._url : this.adplatform.url;
// если тип отзыва 'application' (добавлен с сайта okreview) в url ставим ссылку на детальную странцу заявки
        (this.type === 'application') && (url = `/Application/detail/${this.id}`);

        return url || null;
    }

    /**
     * @returns {string|null}
     */
    get subsourceCode() {
        return this._subsourceCode;
    }

    get isShortBranchesBlock () {
        return !(this.answer.official || this.review?.type === 'application');
    }
}
