import React from "react";
import { getLogoByPlatform } from "../../../../../helpers/index";
import cn from "classnames";

/**
 *
 * @param {Object} item
 * @returns {JSX.Element}
 * @constructor
 */
const SourceBlock = ({item= {}}) => {
    return (
        <>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img
                    src={item.subsourceCode ? getLogoByPlatform(item.subsourceCode) : getLogoByPlatform(item.adplatform.type)}
                    alt={item.adplatform.title}
                    title={item.adplatform.title}
                    width={50}
                />
                {(item.subsourceCode && item.subsourceCode !== item.adplatform.type) && <img
                    src={getLogoByPlatform(item.adplatform.type)}
                    width={20}
                    className={cn('item_subsource-icon')}
                    alt=""
                />}
            </a>
        </>
    )
}

export default SourceBlock;
