export class ApplicationStatusModel {
    constructor(status = {}) {
        this._id = status.id || null;
        this._title = status.title || '';
        this._background = status.background || '';
        this._foreground = status.foreground || '';
        this._sortorder = status.sortorder || '';
    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get background() {
        return this._background.indexOf('#') === -1 ? '#'+this._background : this._background;
    }

    get foreground() {
        return this._foreground.indexOf('#') === -1 ? '#'+this._foreground : this._foreground;
    }

    get sortorder() {
        return this._sortorder;
    }

}
