import PersonModel from "./person";
import moment from "moment-timezone";

class ReviewModel {
    constructor(review = {}) {
        this._author = new PersonModel(review.author || {});
        this._rating = review.rating || null;
        this._text = review.text || '';
        this._time = review.time || null;
    }

    get author() {
        return this._author;
    }

    get rating() {
        return this._rating;
    }

    get text() {
        return this._text;
    }

    get time() {
        return moment
            .utc(this._time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }

}

export default ReviewModel;
