import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import RatingBlock from "./RatingBlock";
import { pluralize } from "../../../../helpers/index";
import { useIsMount } from "../../../../hooks/index";

const Dynamics = ({ chartStore, reviewFilterStore }) => {

    am4core.useTheme(am4themes_animated);

    const chart = useRef(null);
    const isMount = useIsMount();

    const chartsFilter = reviewFilterStore.parseFilterToChart();
    let x = null;
    const [summary, setSummary] = useState({ rating: 0, diff: 0 });
    const [isLoader, setLoader] = useState(true);

    const initChart = (data = []) => {

        x = am4core.create("chart-source3", am4charts.XYChart);
        x.padding(0, 0, 25, 0);

        let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.disabled = true;

        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inside = true;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.name = "value";

        let columnTemplate = series.columns.template;
        columnTemplate.tooltipText = "{tooltip}{date}\nКол-во: {value}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#8BD8AB");
        columnTemplate.width = 25;

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0.5];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        columnTemplate.fillModifier = fillModifier;

        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'dynamics')
            .then((data) => {
                setSummary(data.summary);
                initChart(data.data);
                setLoader(false);
            });
        return () => destroyChart();
    }, []);


    useEffect(() => {
        if(!isMount) {
            chartStore.getData(chartsFilter, 'dynamics')
                .then((data) => {
                    setSummary(data.summary);
                    initChart(data.data);
                });
        }
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset>
            <legend>Динамика оценок</legend>
            {!isLoader && <div className={cn('chart__header', 'header')}>
                <div className={'header__left-side'}>
                    <div className={'number-top'}>
                        <div>{summary.total.toLocaleString('ru-RU')}</div>
                        <span>{pluralize(summary.total, ['оценка', 'оценки', 'оценок'], false)}</span>
                    </div>
                    <div>
                        <RatingBlock
                            rating={summary.diff}
                            className={'number-bottom'}/>
                    </div>
                </div>
            </div>}
            <div className={cn('chart__body')}>
                <div id='chart-source3' style={{ width: '100%', height: '100%' }} />
            </div>
        </fieldset>
    )
}


export default inject('chartStore', 'reviewFilterStore')(observer(Dynamics));
