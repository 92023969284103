import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { scroller } from "react-scroll";
import { Paginator } from "primereact/paginator";
import Sortable from "../Sortable";
import Filter from "../filter/Filter";
import Charts from "../charts/Charts";
import ReviewItem from "./ReviewItem";
import './ReviewList.scss';
import _ from "underscore";
import { useIsMount } from "../../../../hooks/index";

const ReviewList = ({ reviewsStore, reviewFilterStore }) => {
    /** @type {Array<Branch>} items */
    const { items, isLoading } = reviewsStore;
    const { filter, setPager } = reviewFilterStore;
    const [changePage, setChangePage] = useState(1);
    const [pagerFirst, setPagerFirst] = useState(0);
    const [pagerRows, setPagerRows] = useState(10);

    const isMount = useIsMount();

    const onBasicPageChange = (event) => {
        setPagerFirst(event.first);
        setPagerRows(event.rows);
        setChangePage((event.page+1));

        !isMount && scroller.scrollTo('js-reviews-list', {
            duration: 0,
        });
    }

    useEffect(() => {
        setPager({
            number: changePage,
        })

        const newFilter = reviewFilterStore.parseFilterToApi();
        reviewsStore.loadReviews(newFilter);
    }, [changePage]);

    useEffect(() => {
        setPagerFirst(0);
        setPager({
            number: 1,
        })
        const newFilter = reviewFilterStore.parseFilterToApi();
        reviewsStore.loadReviews(newFilter);

    }, [
        JSON.stringify(filter.date),
        JSON.stringify(filter.branches),
        JSON.stringify(filter.sources),
        JSON.stringify(filter.scores),
        JSON.stringify(filter.claim),
        JSON.stringify(filter.attention),
        JSON.stringify(filter.answer),
        JSON.stringify(filter.sorting),
    ]);

    return (
        <>
            <div className={cn('page__review-list', 'page')}>
                <div className={cn('title')}>
                    <h1>Отзывы</h1>
                </div>

                <div className={cn('review-filter')}>
                    <Filter
                        loading={isLoading}
                    />
                </div>

                <div className={cn('review-charts')}>
                    <Charts />
                </div>

                <div className={cn('review-list')} id="js-reviews-list">
                    <div className={cn('review-list__table')}>
                        {isLoading && <div className={'loader'}/>}
                        <div className={cn('review-list__header')}>
                            <div className={cn('review-list__header-left')}>
                                <h3>Отзывы</h3>
                                <span className={cn('count')}>{filter.pager.itemCount}</span>
                            </div>

                            <div className={cn('review-list__header-right')}>
                                <div className={cn('sort__list')}>
                                    <div className={cn('sort__item', filter.sorting.column === 'posted' ? 'active' : '')}>
                                        <Sortable
                                            name="Дата"
                                            sort={filter.sorting}
                                            column='posted'
                                        >
                                            Дата
                                        </Sortable>
                                    </div>

                                    <div className={cn('sort__item', filter.sorting.column === 'score' ? 'active' : '')}>
                                        <Sortable
                                            name="Оценка"
                                            sort={filter.sorting}
                                            column='score'
                                        >
                                            Оценка
                                        </Sortable>
                                    </div>

                                    <div className={cn('sort__item', filter.sorting.column === 'source' ? 'active' : '')}>
                                        <Sortable
                                            name="Источник"
                                            sort={filter.sorting}
                                            column='source'
                                        >
                                            Источник
                                        </Sortable>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={cn('review-list__wrapper')}>
                            {items.length ? items.map((item, key) => (
                                <ReviewItem
                                    data={item}
                                    key={key+'_'+item.id}
                                />
                            )) : 'нет отзывов'}
                        </div>
                    </div>
                </div>

                <div className={cn('paginator-container')}>
                    {filter.pager.itemCount > 0 && <Paginator
                        first={pagerFirst}
                        rows={pagerRows}
                        totalRecords={filter.pager.itemCount}
                        onPageChange={onBasicPageChange}
                    />}
                </div>

            </div>
        </>
    )
}

export default inject('reviewsStore', 'reviewFilterStore')(observer(ReviewList));
