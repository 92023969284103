import {observable, action} from "mobx";
import moment from "moment";
//TODO  переделать под глобальный фильтр

import reviewsService from "../services/review";

class GlobalFilterStore {
    @observable branches = [];

    @observable sources = [];

    _reviewsService;

    constructor() {
        this._reviewsService = reviewsService;
    }

    getBranches() {
        this._reviewsService
            .getBranch()
            .then(data => {
                this.branches = data.items?.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            });
    }

    getSources() {
        this._reviewsService
            .getSources()
            .then(data => {
                this.sources = data.items?.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            });
    }

}

class FilterStore {

    @observable filter = {
        service_id: 0,
        date: {
            from: new Date(moment().subtract(30, 'day').toString()),
            to: new Date()
        },
        manager_id: 0,
        status_id: [],
        query: '',
        sort: {
            column: 'time',
            order: 'desc'
        },
        page: {
            size: 10,
            number: 1
        }
    }

    datatableFields = [
        'page',
        'sort'
    ];

    disabledFields = [
        'query',
        'status_id'
    ]

    @observable numberOfChanges = 0;
    @observable numberOfChangesDatatable = 0;

    @action setFieldValue = (fieldName, fieldValue, action = true) => {

        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
//TODO проверить нужно ли нам  массив disabledFields если у метода есть параметр action?
            if (action === true) {
                if (this.disabledFields.indexOf(fieldName) !== -1) {
                    return;
                }

                if (this.datatableFields.indexOf(fieldName) !== -1) {
                    this.numberOfChangesDatatable++;
                } else {
                    this.numberOfChanges++;
                }

            }
        }
    }

    @action setPageSize = (size) => {
        this.filter.page.size = size;
    }

    @action setPage = (number) => {
        this.filter.page.number = number;
    }

    @action setDefaultManager = (id) => {
        this.filter.manager_id = id;
    }

    @action setDefaultService = (id) => {
        this.filter.service_id = id;
    }


}

const filterStore = new FilterStore();
const globalFilterStore = new GlobalFilterStore();
export {
    filterStore,
    globalFilterStore
}

