import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import "./ChartTraffic.scss";
import cn from "classnames";
import moment from "moment";

const data = {
    "statusCode": 200,
    "data": {
        "summary": {
            "open": {
                "timestamp": "2021-07-01T00:00:00+03:00",
                "rating": 3.7,
                "total": 20,
                "total_reviews": 20
            },
            "close": {
                "timestamp": "2022-01-31T23:59:59+03:00",
                "rating": 1.83,
                "total": 1689,
                "total_reviews": 1138
            },
            "diff": {
                "days": 215,
                "rating": -1.87,
                "total": 1669,
                "total_reviews": 1118
            },
            "adplatforms": [
                1000001,
                1000002,
                1000003,
                1000009,
                1000010
            ]
        },
        "series": [
            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-02-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 450000,
                    "total_reviews": 261,
                    "days": 31
                }
            },

            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-03-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 250000,
                    "total_reviews": 261,
                    "days": 31
                }
            },

            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-04-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 250000,
                    "total_reviews": 261,
                    "days": 31
                }
            },

            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-05-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 800000,
                    "total_reviews": 261,
                    "days": 31
                }
            },

            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-06-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 100000,
                    "total_reviews": 261,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 3.7,
                    "total": 20,
                    "total_reviews": 20,
                    "timestamp": "2021-07-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0.68,
                    "total": 100000,
                    "total_reviews": 261,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2021-08-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 200000,
                    "total_reviews": 21,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2021-09-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 300000,
                    "total_reviews": 21,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2021-10-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 400000,
                    "total_reviews": 21,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2021-11-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 100000,
                    "total_reviews": 21,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2021-12-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 1000000,
                    "total_reviews": 21,
                    "days": 31
                }
            },
            {
                "open": {
                    "rating": 4.38,
                    "total": 728,
                    "total_reviews": 281,
                    "timestamp": "2022-01-01T00:00:00+03:00"
                },
                "diff": {
                    "rating": 0,
                    "total": 900000,
                    "total_reviews": 21,
                    "days": 31
                }
            },


        ]
    }
};

const ChartTraffic = () => {
    am4core.useTheme(am4themes_animated);
    const [loading, setLoading] = useState(true);
    const chart = useRef(null);
    let x = null;

    const parseDynamics = (result) => {
        const tmp = {
            'total': Number(result.summary.close.total),
            'diff': Number(result.summary.diff.total).toLocaleString('ru-RU'),
        }

        const data = result.series.map((item) => {
            return {
                'date': moment(item.open.timestamp).format("DD.MM").toString(),
                'tooltip': (item.diff.days === 1) ? null : (item.diff.days > 7) ? 'Месяц с\n' : 'Неделя с\n',
                'value': (item.diff.total).toLocaleString('ru-RU'),
            }
        })

        return {
            summary: tmp,
            data: data
        }
    }

    const initChart = (data = []) => {

        x = am4core.create("chart-source", am4charts.XYChart);
        x.padding(10, 10, 5, 10);

        let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());

        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.disabled = false;
        //categoryAxis.renderer.labels.template.rotation = 45;
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.horizontalCenter = "middle";
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inside = false;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.name = "value";

        let columnTemplate = series.columns.template;
        columnTemplate.tooltip = new am4core.Tooltip();
        columnTemplate.tooltip.label.fontSize = 12;

        columnTemplate.tooltip.pointerOrientation = 'left';
        columnTemplate.tooltip.getFillFromObject = false;
        columnTemplate.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate.tooltipX = am4core.percent(50);
        columnTemplate.tooltipY = am4core.percent(0);

        columnTemplate.tooltipText = "{tooltip}{date}\nКол-во: {value}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#0FAFE2");
        columnTemplate.width = 50;

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0.5];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        columnTemplate.fillModifier = fillModifier;

        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        const res = parseDynamics(data.data);
        initChart(res.data);

        setLoading(false);

        return () => destroyChart();
    }, []);


    return (
        <>
            <div className={cn('chart')}>
                <div className={cn('chart__header')}>
                    <div className={cn('header__title')}>
                        <div className={cn('position__left')}>
                            <h3>Яндекс.Карты</h3>
                            <span className={cn('separator')}>&nbsp;|&nbsp;</span>
                            <span className={cn('count')}>9 013 090</span>
                            <span className={cn('sub_text')}>просмотров</span>
                        </div>
                        <span className={cn('sub_text', 'position__right')}>Период: месяц</span>
                    </div>
                </div>
                <div id='chart-source' className={cn('chart__body')} style={{ width: '100%', height: '100%' }} />
            </div>
        </>
    );
}

export default ChartTraffic;
