import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import LoginPage from "../pages/login/Login";
import PromoPage from "../pages/promo/Promo";

const External = () => {
    return(
        <React.Fragment>
            <Header/>
            <React.Fragment>
                <Switch>
                    <Route exact path='/'>
                        <PromoPage/>
                    </Route>
                    <Route path='/login'>
                        <LoginPage/>
                    </Route>
                    <Redirect to='/'/>
                </Switch>
            </React.Fragment>
            <Footer/>
        </React.Fragment>
    );
};

export default withRouter(External);
