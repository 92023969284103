import React, { Component } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";

import applicationService from '../../../../../services/application'
import managersService from "../../../../../services/managers";
import applicationStatusService from "../../../../../services/application-status";
import filterService from '../../../../../services/filter';
import autoServicesService from '../../../../../services/services';

import '../../../../App.scss';
import './../ApplicationList.scss';
import './ApplicationListMobile.scss';

import _ from "underscore";
import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import ApplicationListDashboard from "../../common/ApplicatonListDashboard";
import OkMultiSelect from "../../../../common/OkMultiSelect";
import OkFilterCalendar from "../../common/FilterCalendar";
import OkFilterManagers from "../../common/FilterManagers";
import NavigateBtnUp from "../../common/NavigateBtnUp";
import RatingField from "../../common/RatingField";
import NpsBlock from "../../common/NpsBlock";
import ApplicationModel from "../../../../../models/application";
import OkFilterServices from "../../common/FilterServices";
import { useHistory } from "react-router";


@inject('filterStore', 'userStore')
@observer
class ApplicationListMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            period: filterService.getCurrentMonth(),
            error: false,
            first: 0,
            isLoading: false,
            isLoadingTable: false,
            isLoadingDash: false,
            showButtonUp: false,
            dashboardSticky: false,
            applications: [],
            dashboard: {},
            pager: {
                "size": 10,
                "number": 1,
                "pageCount": 0,
                "itemCount": 0
            }
        };
        this.filterStore = this.props.filterStore;
        this.userStore = this.props.userStore;

        this.managers = [
            { "value": 0, "label": "Все менеджеры" }
        ];

        this.services = [
            { "value": 0, "label": "Все филиалы" }
        ];

        this.sortFields = [
            { value: { column: 'time', order: 'desc' }, label: 'По дате заявки: сначала новые' },
            { value: { column: 'number', order: 'desc' }, label: 'По номеру заявки: сначала новые' },
            { value: { column: 'rating', order: 'desc' }, label: 'По оценке: сначала высокие' },
            { value: { column: 'rating', order: 'asc' }, label: 'По оценке: сначала низкие' }
        ]

        this.statuses = [];

        this.periods = [
            { name: 'Сегодня', value: filterService.getToday() },
            { name: 'Вчера', value: filterService.getYesterday() },
            { name: 'Неделя', value: filterService.getCurrentWeek() },
            { name: 'Месяц', value: filterService.getCurrentMonth() }
        ];

        this.managerDisposer = reaction(
            () => this.filterStore.filter.service_id,
            service_id => {
                this.getManagers(service_id);
            }
        );

        this.disposer = reaction(
            () => this.filterStore.numberOfChanges,
            numberOfChanges => {
                this.getApplicationsList();
            }
        );

        this.dataTableDisposer = reaction(
            () => this.filterStore.numberOfChangesDatatable,
            numberOfChangesDatatable => {
                this.getOnlyApplicationsList();
            }
        );
    }

    unBindScrollListener = () => {
        if (this.scrollFunction) {
            window.removeEventListener('scroll', this.scrollFunction);
        }
    }

    componentWillUnmount() {
        this.managerDisposer();
        this.disposer();
        this.dataTableDisposer();
        this.unBindScrollListener();
    }

    bindScrollListener = () => {
        this.scrollFunction = () => {
            let docBody = document.body,
                docElement = document.documentElement,
                scrollTop = (window.pageYOffset || document.documentElement.scrollTop),
                winHeight = docElement.clientHeight,
                docHeight = Math.max(docBody.scrollHeight, docBody.offsetHeight, winHeight, docElement.scrollHeight, docElement.offsetHeight);

// sticky dashboard
            if (scrollTop >= 400) {
                this.setState({ dashboardSticky: true });
            } else {
                this.setState({ dashboardSticky: false });
            }

// кнопка up
            if (scrollTop >= 700) {
                this.setState({ showButtonUp: true });
            } else {
                this.setState({ showButtonUp: false });
            }
// догрузка контента
            if (scrollTop >= (docHeight - winHeight - 200)) {
                this.loadMore();
            }
        }

        window.addEventListener('scroll', this.scrollFunction);

    }

    componentDidMount() {
        this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: 1 }, false);
        this.bindScrollListener();
        this.setState({
            isLoading: true,
        });

//TODO переделать цепочку в Promise.all
        autoServicesService.getServices().then(data => {
            const services = data.map((service) => ({
                value: service.id,
                label: service.title,
            }));

            this.services = [
                ...this.services,
                ...services
            ];
            return managersService.getManagers()
        })
            .then(data => {

                const managers = data.map((manager) => {
                    return { "value": manager.id, "label": manager.fullName };
                });

                this.setDefaultManager(managers);

                this.managers = [
                    ...this.managers,
                    ...managers
                ];

                return applicationStatusService.getApplicationStatus();
            })
            .then(data => {
                const statuses = data.map((status) => {
                    return { "value": status.id, "label": status.title };
                });

                this.statuses = [
                    ...statuses
                ];

                return applicationService.getDashboard(this.filterStore.filter);
            })
            .then(data => {
                this.setState({
                    dashboard: data,
                });

                return applicationService.getApplicationsList(this.filterStore.filter);
            })
            .then((data) => {
                /*
                TODO этот блок вынести в функцию
                    data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                 */
                this.setState({
                    isLoadingTable: false,
                    isLoading: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });
            })
            .catch(() => {
                this.setState({
                    error: true
                });
            });
    }

    setDefaultManager = (managers) => {
        if(managers.length === 1 && this.filterStore.filter.manager_id === 0) {
            this.filterStore.setDefaultManager(managers[0].value);
        }
    }

    getOnlyApplicationsList = () => {
        this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: 1 }, false);
        this.setState({
            isLoadingTable: true,
        });

        applicationService.getApplicationsList(this.filterStore.filter)
            .then(data => {
                this.setState({
                    isLoadingTable: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });
            })
    }

    getApplicationsList = () => {
        this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: 1 }, false);
        this.setState({
            isLoadingTable: true,
            isLoadingDash: true,
        });
        applicationService.getApplicationsList(this.filterStore.filter)
            .then(data => {

                this.setState({
                    isLoadingTable: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });

                return applicationService.getDashboard(this.filterStore.filter)
            })
            .then(data => {
                this.setState({
                    isLoadingDash: false,
                    dashboard: data
                });

            });
    }

    loadMore = () => {
        let pageNum = (this.state.pager.number + 1);

        if (pageNum <= this.state.pager.pageCount) {
            this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: pageNum }, false);

            this.setState({
                isLoadingTable: true,
            });

            this.unBindScrollListener();

            applicationService.getApplicationsList(this.filterStore.filter)
                .then(data => {
                    this.bindScrollListener();

                    this.setState({
                        isLoadingTable: false,
                        applications: [
                            ...this.state.applications,
                            ...data.applications
                        ],
                        pager: data.pager
                    });
                })
        }
    }

    setPeriod = (e) => {
        if (e.target.value) {
            this.filterStore.setFieldValue('date', {
                'from': new Date(e.value[0]),
                'to': new Date(e.value[1])
            });
        }

        this.setState({
            period: e.value
        });
    }


    getApplicationFromFilter = () => {
        this.filterStore.setFieldValue('page', { size: this.filterStore.filter.page.size, number: 1 });
        this.setState({
            isLoadingTable: true,
        });
        applicationService.getApplicationsList(this.filterStore.filter)
            .then(data => {
                this.setState({
                    isLoadingTable: false,
                    applications: data.applications.map(item => {
                        return new ApplicationModel(item);
                    }),
                    pager: data.pager
                });
            })

    }

    getDebounceApplicationsList = _.debounce(this.getApplicationFromFilter, 1000);

    setKeyword = (e) => {
//TODO пока оставим так. потом найду как использовать юникод
        if (e.target.value.length === 0 || e.target.value.trim().match(/[\wа-яА-ЯёЁ\d]/) !== null) {
            this.filterStore.setFieldValue(e.target.name, e.target.value);
            this.getDebounceApplicationsList();
        }
    }

    handleChangeStatuses = () => {
        this.getOnlyApplicationsList();
    }

    handleChangeSort = () => {
        this.getApplicationFromFilter();
    }


    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.filterStore.setFieldValue(name, value);
    }

    handleClearPeriod = () => {
        this.setState({ period: false });
    }

    render() {
        const {
            isLoading,
            error,
            isLoadingTable,
            isLoadingDash,
            applications,
            dashboard,
            showButtonUp,
            dashboardSticky,
        } = this.state;

        let classNameForm = 'application-list';
        if (isLoading) {
            classNameForm += ' application-list__bg-transparent';
        }

        return (
            <React.Fragment>
                <div className={cn('page__application-list', 'page__application-list-mobile', 'page')}>
                    <div className={classNameForm}>
{/*                        {error && <Dialog visible={error}
                                          className={cn('dialogError')}
                                          position={'center'}
                                          onHide={() => this.setState({ 'error': false })}
                        >
                            <div className={'blockError'}>
                                <div className={cn('wrapper', 'error')}>
                                    <div className={cn('text')}
                                         dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_errorTextLoadData }}
                                    />
                                </div>
                            </div>

                        </Dialog>}*/}
                        {isLoading && <div className={cn('form-loading')} />}
                        <React.Fragment>
                            <div className={'title'}>
                                <h1>Список заявок</h1>
                            </div>

                            <div className={cn('application-list__filter')}>
                                <div className={cn('application-list__wrapper')}>
                                    <div className={cn('filter-content')}>
                                        <div className={cn('filter-content__filter')}>
                                            <div className={'text-field'}>
                                                <SelectButton
                                                    options={this.periods}
                                                    value={this.state.period}
                                                    onChange={(e) => this.setPeriod(e)}
                                                    optionLabel="name"
                                                />
                                            </div>
                                            <div className={'date-field'}>
                                                <OkFilterCalendar />
                                            </div>
                                            <div className={'text-field managers-field'}>
                                                <OkFilterServices
                                                    services={this.services}
                                                />
                                            </div>

                                            <div className={'text-field managers-field'}>
                                                <OkFilterManagers
                                                    managers={this.managers}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={cn('dashboard', { dashboard__loading: isLoadingDash }, { dashboard__sticky: dashboardSticky })}
                                        >
                                            <ApplicationListDashboard value={dashboard} />
                                        </div>

                                    </div>
                                    <div className={cn('application-list__search')}>
                                        <div className={'text-field status-field mobile-field'}>
                                            <label htmlFor="status_id">Статус</label>
                                            <OkMultiSelect
                                                inputId="status_id"
                                                name="status_id"
                                                options={this.statuses}
                                                value={this.filterStore.filter.status_id}
                                                onChange={(e) => this.handleChange(e)}
                                                maxSelectedLabels={1}
                                                onClose={() => {
                                                    this.handleChangeStatuses()
                                                }}
                                                selectedItemsLabel={'Выбрано статусов: {0}'}
                                                placeholder={'Все статусы'}
                                                optionLabel="label"
                                            />
                                        </div>
                                        <div className={'text-field status-field mobile-field'}>
                                            <label htmlFor="sort">Сортировка</label>
                                            <Dropdown inputId="sort"
                                                      name="sort"
                                                      options={this.sortFields}
                                                      value={this.filterStore.filter.sort}
                                                      onChange={(e) => this.handleChange(e)}
                                                      placeholder={'По дате заявки: сначала новые'}
                                                      optionLabel="label"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <React.Fragment>
                                <div
                                    className={cn('application-list__plate', { application__loading: isLoadingTable })}
                                >
                                    {applications.map((item, key) => {
                                        return (
                                            <ApplicationPlateItem
                                                item={item}
                                                key={key}
                                            />
                                        );
                                    })}
                                    {showButtonUp && <NavigateBtnUp />}

                                </div>
                            </React.Fragment>

                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const ApplicationPlateItem = (props) => {
    const { item } = props;
    const history = useHistory();
    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/Application/detail/${item.id}`);
    }

    return (
        <div className={cn('application-plate__item')} onClick={handleClick}>
            <div className={cn('date')}>{item.time}</div>
            <div className={cn('status')}>
                <span className={`p-tag application-tag mobile-tag`}
                      style={{
                          'color': item.status.foreground,
                          'backgroundColor': item.status.background
                      }}
                >
                    {item.status.title.toUpperCase()}
                </span>
            </div>
            <div className={cn('descr')}>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>№ заявки</div>
                    <div className={cn('col-text')}>{item.number}</div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Оценка</div>
                    <div className={cn('col-text')}>
                        <RatingField
                            review={item.review}
                        />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>NPS</div>
                    <div className={cn('col-text')}>
                        <NpsBlock
                            nps={item.nps}
                        />
                    </div>
                </div>

                <div className={cn('row')}>
                    <div className={cn('col-title')}>Менеджер</div>
                    <div className={cn('col-text')}>
                        {item.manager.lastname} {item.manager.firstname}
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Клиент</div>
                    <div className={cn('col-text')}>
                        {item.customer.lastname} {item.customer.firstname}
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>VIN</div>
                    <div className={cn('col-text')}>{item.purchase.car.vin}</div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Автомобиль</div>
                    <div className={cn('col-text')}>
                        {item.purchase.car.brand} {item.purchase.car.model}, {item.purchase.car.year}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationListMobile;
