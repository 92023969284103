import authService from '../services/auth';
import api from '../services/api';
import {serialize} from '../helpers'
import moment from "moment";

const CHARTS_URL = {
    rating : {
        url: 'rating',
    },
    dynamics: {
        url: 'score-dynamic',
    },
    pie: {
        url: 'source-type',
    },
    grade: {
        url: 'score-distribution',
    }
}

const SOURCE = {
    'avito': {
        name: 'Avito',
        color: '#0FAFE2'
    },
    'yandex': {
        name: 'Яндекс',
        color: '#6262F3'
    },
    'google': {
        name: 'Google',
        color: '#FDAC4D'
    },
    '2gis': {
        name: '2ГИС',
        color: '#3ACD77'
    },
    'otzovik': {
        name: 'Отзовик',
        color: '#3A7D78'
    },
    'flamp': {
        name: 'Flamp',
        color: '#5fff77'
    },
    'irecommend': {
        name: 'IRecommend',
        color: '#AACD99'
    },
    'zoon': {
        name: 'Zoon',
        color: '#AACD99'
    },
    'none': {
        name: 'None',
        color: '#AACD99'
    },

}

class ChartService {

    getData = (filter, chartType) => {
        const queryString = serialize(filter);
        const url = CHARTS_URL[chartType].url;

        return api.get(
            `/extreview/reviews/chart/${url}?${queryString}`,
        ).then((result) => {
            if(chartType === 'rating'){
                return this.parseRating(result);
            }
            if(chartType === 'dynamics') {
                return this.parseDynamics(result);
            }
            if(chartType === 'pie') {
                return this.parsePie(result);
            }
            if(chartType === 'grade') {
                return this.parseGrade(result);
            }

        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    parseGrade = (result) => {
        const data = result.series.map((item) => {
            let change;
            let textColor;

            const total = item.diff.total.toLocaleString('ru-RU');

            if(item.diff.total > 0) {
                change = `+${total}`;
                textColor = '#6FCF97';
            }
            else if(item.diff.total === 0){
                change = `${total}`;
                textColor = '#6FCF97';
            }
            else {
                change = `${total}`;
                textColor = '#EB5757';
            }

            return  {
                'rating': item.score.toLocaleString('ru-RU'),
                'value': item.close.total.toLocaleString('ru-RU'),
                'new': change,
                'color': '#6FCF97',
                'textColor': textColor
            }
        })

        return {
            data
        }

    }

    parsePie = (result) => {
        const tmp = {
            'total': result.summary.close.total.toLocaleString('ru-RU'),
        }

        const data = result.series.map((item) => {
            let change;
            let textColor;

            const total = item.diff.total.toLocaleString('ru-RU');
//TODO не нашел как в am4charts получить значение переменной из json. вставил формат сюда.
            if(item.diff.total > 0) {
                change = `+${total}`;
                textColor = '#6FCF97';
            }
            else if(item.diff.total === 0){
                change = `${total}`;
                textColor = '#6FCF97';
            }
            else {
                change = `${total}`;
                textColor = '#EB5757';
            }

            return {
                "source": SOURCE[item.source].name || SOURCE['none'].name,
                "count": item.close.total.toLocaleString('ru-RU'),
                "change": change,
                "textColor": textColor,
                "color": SOURCE[item.source].color || SOURCE['none'].color,
            }
        });

        return {
            summary: tmp,
            data
        }
    }

    parseRating = (result) => {
        const tmp = {
            'total': Number(result.summary.close.rating).toLocaleString('ru-RU'),
            'rating': Number(result.summary.diff.rating).toLocaleString('ru-RU'),
            'total_rating': Number(result.summary.close.total),
            'total_reviews': Number(result.summary.close.total_reviews),
        }

        const data = result.series.map((item) => {
            return {
                'date': moment(item.timestamp).format("YYYY-MM-DD").toString(),
                'value': item.rating,
                'tooltipDate': moment(item.timestamp).format("DD.MM.YYYY").toString(),
            }
        })

        return {
            summary: tmp,
            data
        }
    }

    parseDynamics = (result) => {
        const tmp = {
            'total': Number(result.summary.close.total),
            'diff': Number(result.summary.diff.total).toLocaleString('ru-RU'),
        }

        const data = result.series.map((item) => {
            return {
                'date': moment(item.open.timestamp).format("DD.MM.YYYY").toString(),
                'tooltip': (item.diff.days === 1) ? null : (item.diff.days > 7) ? 'Месяц с\n' : 'Неделя с\n',
                'value': item.diff.total.toLocaleString('ru-RU'),
            }
        })

        return {
            summary: tmp,
            data: data
        }
    }

}

export default new ChartService();
