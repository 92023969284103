import { action, observable, toJS } from "mobx";
import _ from "underscore";
import autoServicesService from "../services/services";
import uiStore from "./ui";
import applicationService from "../services/application";
import * as Sentry from "@sentry/browser";
import { ApplicationRequestModel, CarModel, CustomerModel, PurchaseModel } from "../models/purchase";
import moment from "moment";

class ApplicationFormAddStore {

    /**
     *
     * @type {{carColor: string,
     * customerPhone: string,
     * purchaseDate: string,
     * carBrand: string,
     * customerFirstname: string,
     * autoServices: string,
     * customerEmail: string,
     * carVin: string,
     * customerLastname: string,
     * carYear: string,
     * carModel: string}}
     */
    @observable initForm = {
        autoServices: '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carColor: '',
        carVin: '',
        customerLastname: '',
        customerFirstname: '',
        customerPhone: '',
        customerEmail: '',
        purchaseDate: '',
    };

    /**
     *
     * @type {[]}
     */
    @observable services = [];

    /**
     *
     * @private
     */
    _requiredFields = [
        'carBrand',
        'carModel',
        'carYear',
        'carColor',
        'carVin',
        'customerLastname',
        'customerFirstname',
        'customerPhone',
        'purchaseDate',
        'autoServices',
    ];

    /**
     *
     * @type {{}}
     * @private
     */
    _errorsForm = {};

    constructor(props) {
        this.uiStore = props.uiStore;
    }

    @action getForm = () => {
        return this.initForm;
    }

    @action resetForm = () => {
        this.initForm = {
            autoServices: '',
            carBrand: '',
            carModel: '',
            carYear: '',
            carColor: '',
            carVin: '',
            customerLastname: '',
            customerFirstname: '',
            customerPhone: '',
            customerEmail: '',
            purchaseDate: '',
        };
    }

    @action getServices = () => {
        this.uiStore.startLoading();
        autoServicesService.getServices().then(data => {
            this.services = data.map((service) => ({
                value: service.id,
                label: service.title,
            }));

            if(this.services.length === 1){
                this.setFieldValue('autoServices', this.services[0].value);
            }

            this.uiStore.endLoading();
        })
    }

    /**
     *
     * @returns {{}}
     */
    getErrorsForm = () => {
        return this._errorsForm;
    }

    /**
     *
     * @param fieldName
     * @param fieldValue
     */
    @action setFieldValue = (fieldName, fieldValue) => {
        if (fieldName in this.initForm) {
            this.initForm[fieldName] = fieldValue;
        }
    };


    onSubmit = () => {

        const data = new ApplicationRequestModel({
            service_id: this.initForm.autoServices,
            purchase: new PurchaseModel({
                date: moment(this.initForm.purchaseDate).format("YYYY-MM-DD").toString(),
                car: new CarModel({
                    brand: this.initForm.carBrand,
                    model: this.initForm.carModel,
                    year: this.initForm.carYear,
                    color: this.initForm.carColor,
                    vin: this.initForm.carVin,
                })
            }),
            customer: new CustomerModel({
                    lastname: this.initForm.customerLastname,
                    firstname: this.initForm.customerFirstname,
                    phone: this.initForm.customerPhone,
                    email: this.initForm.customerEmail,
                },
            )
        }).toApiObject();

        this.uiStore.startLoading();
        applicationService.saveApplication(data)
            .then(() => {
                //TODO тут будет вызов окна сообщения из ui stores
                this.setState({
                    success: true
                });
                return true;
            })
            .catch((e) => {
                //TODO тут будет вызов окна ошибки из error stores (в немже реализуем лог в центри)
                Sentry.captureException(new Error(JSON.stringify(e)));
                this.setState({
                    error: true
                });
                return false
            })
            .finally(() => {
                this.uiStore.endLoading();
            });
    }

    /**
     *
     * @returns {{}}
     */
    validateForm = (values) => {
        _.mapObject(values, (val, key) => {
            let fieldValue = (val && typeof val === 'string') ? val.trim() : val;

            if (this._requiredFields.includes(key)) {
                this.setErrorField(key, this.checkField(fieldValue));
            }

            if (key === 'carYear') {
                this.setErrorField(key, this.checkCarYear(fieldValue));
            }

            if (key === 'carVin') {
                this.setErrorField(key, this.checkCarVin(fieldValue));
            }

            if (key === 'customerEmail') {
                this.setErrorField(key, this.checkCustomerEmail(fieldValue));
            }
        });
        return toJS(this.getErrorsForm());
    }

    setErrorField = (fieldName, error) => {
        if(error){
            this._errorsForm[fieldName] = error;
        }
    }

    /**
     *
     * @param field
     * @returns {boolean}
     */
    checkField = (field) => {
        return !field;
    }

    /**
     *
     * @returns {string|boolean}
     */
    checkCarYear = (value) => {
        if (!value
            || isNaN(value)
            || (Number(value) < 1900 || Number(value) > new Date().getFullYear())
        ) {
            return 'От 1900 до ' + new Date().getFullYear();
        }
        return false;
    }

    /**
     *
     * @returns {string|boolean}
     */
    checkCarVin = (value) => {
        if (!value
            || !/^[A-HJ-NPR-Z\d]{14}\d{3}$/i.test(value)
            || value.length !== 17) {
            return 'Некорректный формат';
        }
        return false;
    }

    /**
     *
     * @returns {string|boolean}
     */
    checkCustomerEmail = (value) => {
        if (value !== ''
            && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return 'Некорректный формат';
        }
        return false;
    }

}


const applicationFormAddStore = new ApplicationFormAddStore({ uiStore });
export default applicationFormAddStore;
