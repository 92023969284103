import React from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { AD_PLATFORM_TYPE } from "../../../../../models/ad-platform";
import { inject, observer } from "mobx-react";

const MESSAGE_TITLE = 'Внимание';
const MESSAGE_DETAIL = 'Для того, чтобы отвечать на отзывы, размещенные на Flamp, необходимо настроить  доступ для сервиса OKReview. Свяжитесь с Вашим менеджером - он поможет быстро и просто подключить этот функционал.';

const AnswerButton = ({ uiStore, item, setShowAnswerBlock }) => {
    return (
        <>
            {(!item.answer.allowed && item.subsourceCode === 'flamp') && <Button
                label='Ответить'
                className={cn('p-button-outlined')}
                onClick={() => {
                    uiStore.setMessage(
                        {
                            severity: 'warn',
                            summary: MESSAGE_TITLE,
                            detail: MESSAGE_DETAIL,
                        }
                    );
                }}
            />}

            {(item.answer.allowed && item.type === 'review') && <Button
                label='Ответить'
                disabled={item.adplatform.title === AD_PLATFORM_TYPE.AVITO && item.answer.count >= 0}
                className={cn('p-button-outlined')}
                onClick={() => {
                    setShowAnswerBlock(showAnswerBlock => !showAnswerBlock)
                }}
            />}

            {item.type === 'application' && <a href={item.url} target="_blank">
                <Button
                    label='См. заявку'
                    className={cn('p-button-outlined')}
                />
            </a>}

        </>
    )
}

export default inject('uiStore')(observer(AnswerButton));
