import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {Dropdown} from "primereact/dropdown";

@inject('filterStore')
@observer
class FilterServices extends Component {
    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.filterStore.setFieldValue(name, value, false);

        //TODO временный вечный костыль, к задаче OK-308.
        // во время сбрасывания менеджеров в "все менеджеры" происходит глюк в работе фильтра.
        // естть идея при смене значения фильтра this.filterStore.setFieldValue возвращать промис и уже после
        // него делать сброс менеджера
        setTimeout(()=>{
            this.resetManagers();
        }, 0);

    }

    resetManagers = () => {
        this.filterStore.setFieldValue('manager_id', 0);
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <label htmlFor="service">Филиал</label>
                    <Dropdown inputId="service_id"
                              name="service_id"
                              options={this.props.services}
                              value={this.filterStore.filter.service_id}
                              onChange={(e) => this.handleChange(e)}
                              placeholder={'Все Филиалы'}
                              filter
                              optionLabel="label"/>
                </React.Fragment>
            </div>
        )
    }
}
export default FilterServices;
