import authService from "./auth";

class ReviewRequestService {

    saveReviewRequest = (data) => {
        return authService.post(
            '/site/callback',
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }
}

const reviewRequestService = new ReviewRequestService()
export default reviewRequestService;
