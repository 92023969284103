import moment from "moment-timezone";

export class ApplicationRequestModel {
    constructor(applicationRequest= {service_id: null, purchase: {}, customer: {}}) {
        this._service_id = applicationRequest.service_id || null;
        this._purchase = new PurchaseModel(applicationRequest.purchase) || {};
        this._customer = new CustomerModel(applicationRequest.customer) || {};
    }

    get service_id() {
        return this._service_id;
    }

    get purchase() {
        return this._purchase;
    }

    get customer() {
        return this._customer;
    }

    toApiObject() {
        return {
            service_id: this.service_id,
            purchase: this.purchase.toApiObject(),
            customer: this.customer.toApiObject(),
        };
    }

}

export class PurchaseModel {
    constructor(purchase = {}) {
        this._date = purchase.date;
        this._car = new CarModel(purchase.car) || {}
    }

    get car() {
        return this._car;
    }

    get date() {
        return this._date;
    }

    get dateFormatted() {
        return moment
            .utc(this._date)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateDisplayFormat)
            .toString()
    }

    toApiObject() {
        return {
            date: this.date,
            car: this.car.toApiObject(),
        };
    }

}

export class CarModel {
    constructor(car = {}) {
        this._brand = car.brand;
        this._model = car.model;
        this._year = car.year;
        this._color = car.color;
        this._vin = car.vin;
    }

    get brand() {
        return this._brand;
    }
    get model() {
        return this._model;
    }
    get year() {
        return this._year;
    }
    get color() {
        return this._color;
    }
    get vin() {
        return this._vin;
    }

    toApiObject() {
        return {
            brand: this.brand,
            model: this.model,
            year: this.year,
            color: this.color,
            vin: this.vin,
        }
    }
}

export class CustomerModel {
    constructor(customer = {}) {
        this._lastname = customer.lastname;
        this._firstname = customer.firstname;
        this._phone = customer.phone;
        this._email = customer.email;
    }

    get lastname() {
        return this._lastname;
    }

    get firstname() {
        return this._firstname;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    toApiObject() {
        return {
            lastname: this.lastname,
            firstname: this.firstname,
            phone: this.phone,
            email: this.email,
        }
    }
}
