import AnalyticsEntityModel from '../models/analyticsEntity';
import okAnalyticsStore from '../stores/okAnalytics';
import developersTagStore from "../stores/developersTag";
import userStore from '../stores/user';
import authService from "../services/auth";
import OAuth from '../services/OAuth';
import api from '../services/api';
import { v4 as uuidv4 } from 'uuid';


//TODO убрать в .env
const INTERVAL_MS = 1000 * 60 * 10; //интервал 10мин
const SESSION_TIMEOUT_MS = 1000 * 60 * 30; //таймаут активности 30мин
const START = false;
const DEBUG = false;
const EVENTS = [
    'beforeunload',
];

class OkAnalyticsService {

    #okAnalyticsStore;
    #developersTagStore;
    #userStore;
    #timer;
    #sessionTimer;
    #uniqueHash;

    constructor(okAnalyticsStore, developersTagStore, userStore) {

        this.#okAnalyticsStore = okAnalyticsStore;
        this.#developersTagStore = developersTagStore;
        this.#userStore = userStore;
        this.#uniqueHash = this.createSessionId();
        !this.#developersTagStore.isDeveloper && START && this.startSendTimer();
    }

    get uniqueHash() {
        return this.#uniqueHash;
    }

    startSessionTimer() {
        clearTimeout(this.#sessionTimer);

        this.#sessionTimer = setTimeout(() => {
            this.#okAnalyticsStore.data.events.push(
                new AnalyticsEntityModel(
                    {
                        ...{
                            page: 'site',
                            target: 'page',
                            event: 'inactivity_timeout',
                        },
                        ...{
                            sessionId: this.uniqueHash,
                            date: (new Date().getTime() / 1000).toFixed(),
                        }
                    }
                )
            );
        }, SESSION_TIMEOUT_MS);
    }

    startSendTimer() {
        this.#timer = setInterval(() => {
            //TODO пытаемся поставить в очередь на отправку. если ок. то чистим данные
            this.#okAnalyticsStore.data.events.length > 0 && this.sendData() && this.clearData();
        }, INTERVAL_MS);
    }

    stopSendTimer() {
        clearInterval(this.#timer);
    }

    startEventListeners() {
        EVENTS.map(event => window.addEventListener(event, this.listener));
    }

    removeEventListeners() {
        EVENTS.map(event => window.removeEventListener(event, this.listener));
    }

    listener = () => {
        okAnalyticsService.addEntity({
            page: 'site',
            target: 'page',
            event: 'close_page',
        });

        this.#okAnalyticsStore.data.length > 0 && this.sendData() && this.clearData();
    }

    createSessionId() {
        return uuidv4();
    }

    /**
     *
     * @param {AnalyticsEntityModel} object
     */
    addEntity(object = {}) {
        this.#okAnalyticsStore.data.events.push(
            new AnalyticsEntityModel(
                {
                    ...object,
                    ...{
                        sessionId: this.uniqueHash,
                        date: (new Date().getTime() / 1000).toFixed(),
                    }
                }
            )
        );
        this.startSessionTimer();
    }

    sendData() {
        this.#okAnalyticsStore.data.userId = OAuth.getTokens().accessToken;
        DEBUG && console.log(JSON.stringify(this.#okAnalyticsStore.data));
        return api.sendBeacon('/webhooks/okreview/user-activity', JSON.stringify(this.#okAnalyticsStore.data));
    }

    clearData() {
        this.#okAnalyticsStore.clearData();
        DEBUG && console.log('очистили this.#okAnalyticsStore.data');
    }
}

const okAnalyticsService = new OkAnalyticsService(okAnalyticsStore, developersTagStore, userStore);
export default okAnalyticsService;
