import React, { Component, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from 'classnames';
import { Button } from "primereact/button";
import ReactLogo from './../images/header_logo.svg';
import MobileMenu from '../components/common/MobileMenu';
import Profile from "../components/common/Profile";
import './Header.scss';

const Header = inject('userStore')(observer(({userStore})=>{
    const { logout, isAuthorized, profile } = userStore;

    const onLogout = () => {
        logout().then(() => {
            document.location.replace('/');
        });
    }

    return (
        <>
            <div className={'main-header'}>
                <div className={'main-header__wrapper'}>
                    {process.env.REACT_APP_OAUTH2 === 'false' && <div className={cn('main-header__logo')}>
                        <Link to='/' data-tag='index'><img src={ReactLogo} alt="" width={146} height={37} /></Link>
                    </div>}
                    {isAuthorized && <Menu allowed={profile.allowed} />}
                    {process.env.REACT_APP_OAUTH2 === 'false' && <UserInfo
                        handleOnClick={onLogout}
                    />}
                    {isAuthorized && <MobileMenu />}
                </div>
            </div>
        </>
    )
}))

const Menu = ({ allowed }) => {

    return (
        <ul className={cn('main-menu', {'no-padding' : process.env.REACT_APP_OAUTH2 === 'true'})}>
            {allowed.createApplication && process.env.REACT_APP_OAUTH2 === 'false' && <li className={cn('main-menu__item')}>
                <NavLink to='/Application/add' data-tag='application_add'>Создать заявку</NavLink>
            </li>}
            {allowed.listApplications && <li className={cn('main-menu__item')}>
                <NavLink
                    to='/Application/list'
                    data-tag='application_list'
                    isActive={(match, location) => {
                        return !location.pathname.indexOf('/Application/detail') || !location.pathname.indexOf('/Application/list') ;
                    }}

                >Список заявок</NavLink>
            </li>}
            {allowed.listReviews && <li className={cn('main-menu__item')}>
                <NavLink to='/Review/list' data-tag='review_list'>Мониторинг</NavLink>
            </li>}
            {allowed.analytics && <li className={cn('main-menu__item')}>
                <NavLink
                    to='/analytics/applications'
                    data-tag='analytics_applications'
                    isActive={(match, location) => {
                        return !location.pathname.indexOf('/analytics/');
                    }}
                >Аналитика</NavLink>
            </li>}
        </ul>
    );
}

const UserInfo = inject("userStore")((props) => {
    const location = useLocation();
    const { userStore } = props;
    return (
        <React.Fragment>
            {(!userStore.isAuthorized && location.pathname !== '/login') &&
            <div className={cn('main-header__btn', 'btn-login')}>
                <Link to='/login' data-tag='login'><Button label='Войти' className={cn('btn-outlined')} /></Link>
            </div>}
            {(userStore.isAuthorized) &&

            <div className={cn('profile-container')}>
                <Profile
                    handleOnClick={props.handleOnClick}
                />
            </div>

            }
        </React.Fragment>
    );
});

export default Header;
