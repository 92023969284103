/**
 * @typedef {string} AdPlatformType
 **/

/**
 * @readonly
 * @enum {AdPlatformType}
 */
export const AD_PLATFORM_TYPE = {
    AVITO: 'avito',
    GOOGLE: 'google',
    YANDEX: 'yandex',
    DGIS: '2gis',
    OTZOVIK: 'otzovik',
    FLAMP: 'flamp',
    IRECOMMEND: 'irecommend',
    ZOON: 'zoon',
};

export default class AdPlatform {
    constructor(adPlatform) {
        this._id = adPlatform.id || null;
        this._type = adPlatform.type || null;
        this._title = adPlatform.title || null;
        this._url = adPlatform.url || null;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {AdPlatformType|null}
     */
    get type() {
        return this._type;
    }

    /**
     * @return {string|null}
     */
    get title() {
        return this._title;
    }

    /**
     * @return {string|null}
     */
    get url() {
        return this._url;
    }
}
