import authService from '../services/auth';
import api from './api';
import Answer from "../models/answer";

class AnswerService {
    /**
     * @return {Promise<{pager: {}, sorting: {}, items: Array<Answer>}>}
     */
    getList(reviewId = {}) {
        return api
            .get(`/extreview/reviews/${reviewId}/answers`)
            .then((data) => {
                return {
                    items: data.items.map(item => new Answer(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            });
    }

    getItem(reviewId, id) {
        return api
            .get(`/extreview/reviews/${reviewId}/answers/${id}`)
            .then((data) => new Answer(data))
            .catch(err => {
                return Promise.reject(err);
            });
    }

    /**
     *
     * @param reviewId
     * @param data
     * @returns {Promise<number>}
     */
    saveAnswer(reviewId, data= {}) {
        return api
            .post(`/extreview/reviews/${reviewId}/answers`, {text: data})
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

}

export default new AnswerService();
