import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { action, observable } from "mobx";
import cn from "classnames";
import MobileMenuHambIco from "../../images/main_menu_hamb.svg";
import { Link } from "react-router-dom";
import ReactLogo from "../../images/header_logo.svg";
import MobileMenuCloseIco from "../../images/main_menu_close.svg";
import './MobileMenu.scss';

@inject('userStore')
@observer
class MobileMenu extends Component {
    @observable showMenu = false;

    onLogout = () => {
        const { userStore } = this.props;
        this.toggleMenu();
        userStore.logout().then();
    }

    @action toggleMenu = () => {
        let elem = document.getElementsByClassName('page')[0];
        if (this.showMenu === true) {
            this.showMenu = false;
            elem.classList.remove('page-menu');
        } else {
            this.showMenu = true;
            elem.classList.add('page-menu');
        }
    }

    render() {
        const {
            userStore,
        } = this.props;

        return (
            <div className={cn('mobile-menu')}>
                <div className={cn('mobile-menu__hamb')} onClick={this.toggleMenu}>
                    <img src={MobileMenuHambIco} alt="" width={'30px'} height={'30px'} />
                </div>
                {this.showMenu && <div className={cn('mobile-menu__plate')}>
                    <div className={'main-header'}>
                        <div className={'main-header__wrapper'}>
                            <div className={cn('main-header__logo mobile-menu__logo')} onClick={this.toggleMenu}>
                                <Link to='/' data-tag='index'><img src={ReactLogo} alt="" /></Link>
                            </div>
                            <div className={cn('mobile-menu__hamb')} onClick={this.toggleMenu}>
                                <img src={MobileMenuCloseIco} alt="" width={'30px'} height={'30px'} />
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li className={cn('mobile-menu__item mobile-menu__profile')}>
                            <div className={cn('mobile-menu__client-name')}>
                                {userStore.userFullName}
                            </div>
                            <div className={cn('mobile-menu__client-title')}>{userStore.clientTitle}</div>
                        </li>
                        {userStore.profile.allowed.createApplication &&
                        <li className={cn('mobile-menu__item mobile-menu__close')} onClick={this.toggleMenu}>
                            <Link to='/Application/add' data-tag='application_add'>Создать заявку</Link>
                        </li>}
                        {userStore.profile.allowed.listApplications &&
                        <li className={cn('mobile-menu__item mobile-menu__close')} onClick={this.toggleMenu}>
                            <Link to='/Application/list' data-tag='application_list'>Список заявок</Link>
                        </li>}
                        {userStore.profile.allowed.listReviews &&
                        <li className={cn('mobile-menu__item mobile-menu__close')} onClick={this.toggleMenu}>
                            <Link to='/Review/list' data-tag='review_list'>Мониторинг</Link>
                        </li>}
                        {userStore.profile.allowed.analytics &&
                        <li className={cn('mobile-menu__item mobile-menu__close')} onClick={this.toggleMenu}>
                            <Link to='/analytics/applications' data-tag='analytics_applications'>Аналитика</Link>
                        </li>}
                        <li
                            className={cn('mobile-menu__item mobile-menu__exit mobile-menu__close')}
                            data-tag='logout'
                            onClick={() => {
                                this.onLogout();
                            }}
                        >
                            Выход
                        </li>

                    </ul>
                </div>}
            </div>
        )

    }
}

export default MobileMenu;
