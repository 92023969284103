import axios from "axios";

class Api {

    /**
     *
     * @type {Object}
     */
    client = null;

    headers = {};

    constructor() {
        this.appStorage = localStorage;

        this.client = axios.create({
            baseURL: process.env.REACT_APP_apiUrl,
        });

        this.headers = { 'Content-Type': 'application/json' }

    }


    async put(url, data) {
        try {
            const response = await this.client.put(url, data, { headers: this.headers });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (e) {
            return Promise.reject(e.response.data);
        }

    }

    async get(url) {
        try {
            const response = await this.client.get(url, { headers: this.headers });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async delete(url) {
        try {
            const response = await this.client.delete(url, { headers: this.headers });
            const result = await response.data;
            if (result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async post(url, data) {
        try {
            const response = await this.client.post(url, data, { headers: this.headers });
            const result = await response.data;
            if (result.statusCode === 201 || result.statusCode === 200) {
                return result.data;
            } else {
                return Promise.reject(result);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     *
     * @param {String} url
     * @param {String} data
     * @returns {boolean}
     */
    sendBeacon (url, data) {
        return navigator.sendBeacon(process.env.REACT_APP_apiUrl+url, data);
    }

}

const apiService = new Api();
export default apiService;
