import moment from "moment";

export class FilterModel {
    constructor(filter = {}) {
        this.service_id = filter.service_id || 0;
        this.date = new FilterDateModel(filter.date);
        this.manager_id = filter.manager_id || 0;
        this.status_id = filter.status_id.map(i => {
            return i;
        }) || [];
        this.query = filter.query || '';
        this.sort = new FilterSortModel(filter.sort);
        this.page = new FilterPageModel(filter.page);
    }
}

export class FilterDashboardModel {
    constructor(filter = {}) {
        this.service_id = filter.service_id || 0;
        this.date = new FilterDateModel(filter.date);
        this.manager_id = filter.manager_id || 0;
    }
}

class FilterDateModel {
    constructor(date = {}) {
        this.from = moment(new Date(date.from)).format(process.env.REACT_APP_datePickerFormat).toString()
            || moment(new Date()).format(process.env.REACT_APP_datePickerFormat).toString();

        this.to = moment(new Date(date.to)).format(process.env.REACT_APP_datePickerFormat).toString()
            || moment(new Date()).format(process.env.REACT_APP_datePickerFormat).toString();
    }
}

class FilterSortModel {
    constructor(sort = {}) {
        this.column = sort.column || 'time';
        this.order = sort.order || 'desc';
    }
}

class FilterPageModel {
    constructor(page = {}) {
        this.size = page.size || 10;
        this.number = page.number || 1;
    }
}
