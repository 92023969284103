import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "mobx-react";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import './custom/common.scss';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import stores from './stores/index';

Sentry.init({
    beforeSend(event, hint) {
        if (hint.originalException === "Timeout") return null;
        return event;
    },
    allowUrls: [/https?:\/\/([\w\d-]+\.)*okreview\.ru/],
    dsn: "https://1f0091b609964af5a8003d692fa25149@o292051.ingest.sentry.io/5405755",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <BrowserRouter>
        <Provider {...stores}>
            <App/>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
serviceWorker.unregister();
