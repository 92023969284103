import cn from "classnames";
import DbIco1 from "../../../../images/dashboard_ico1.svg";
import DbIco2 from "../../../../images/dashboard_ico2.svg";
import DbIco3 from "../../../../images/dashboard_ico3.svg";
import DbIco4 from "../../../../images/dashboard_ico4.svg";
import DbIco5 from "../../../../images/dashboard_ico5.svg";
import React from "react";
import './ApplicationListDashboard.scss';


function ApplicationListDashboard (props) {

    const {value} = props;

    return (
        <React.Fragment>
            <div className={cn('filter-content__dashboard')}>
                {value && value.applications && <ul>
                <li className={cn('item1')}>
                    <span><img src={DbIco1} alt=""/> {value.applications.total}</span>
                    Всего заявок
                </li>
                <li className={cn('item2')}>
                    <span><img src={DbIco2} alt=""/> {value.applications.period}</span>
                    Новых заявок <br/>за период
                </li>
                <li className={cn('item3')}>
                    <span><img src={DbIco3} alt=""/> {value.applications.progress}</span>
                    Заявки в работе
                </li>
                <li className={cn('item4')}>
                    <span><img src={DbIco4} alt=""/> {value.reviews.total}</span>
                    Всего отзывов
                </li>
                <li className={cn('item5')}>
                    <span><img src={DbIco5} alt=""/> {value.reviews.period}</span>
                    Новых отзывов <br/>за период
                </li>
            </ul>}
        </div>
        </React.Fragment>
    );
}

export default ApplicationListDashboard;
