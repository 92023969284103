import moment from "moment";
// eslint-disable-next-line no-unused-vars
import ru from "moment/locale/ru";

class FilterService {

    debounceMs = 1000;

    constructor() {
        moment.locale('ru');
    }

    getYesterday = () => {
        return [
            new Date(moment().subtract(1, 'day').toString()),
            new Date(moment().subtract(1, 'day').toString())
        ];
    }

    getToday = () => {
        return [
            new Date(moment().toString()),
            new Date(moment().toString())
        ];
    }

    getCurrentWeek = () => {
        return [
            new Date(moment().subtract(7, 'day').toString()),
            new Date(moment().toString()),
        ];
    }

    getCurrentMonth = () => {
        return [
            new Date(moment().subtract(30, 'day').toString()),
            new Date(moment().toString()),
        ];

    }

    onChangeFilter = (filter) => {
    }

    /**
     *
     * @param arrObjects
     * @param key
     * @returns {*}
     */
    uniqObjectFromArray = (arrObjects, key) => {
        let tmp = [];

        const check = (i) => {
            if (tmp.indexOf(i[key]) === -1) {
                tmp.push(i[key]);
                return true;
            }
            return false;
        }

        return arrObjects.filter((i) => {
            return check(i);
        });
    }

}

const filterService = new FilterService();
export default filterService;
