import authService from "./auth";
import api from "./api";
import {ManagerModel} from "../models/manager";

class ManagersService {

    #managers = []

    get managers() {
        return this.#managers;
    }

    set managers(managers) {
        this.#managers = managers;
    }

    getManagers = (service_id) => {
        const url = (!service_id) ? `/managers` :  `/managers?service_id=${service_id}`;
        if (this.managers.length > 0) {
            return Promise.resolve(this.managers);
        } else {
            return api.get(
                url,
            ).then((result) => {
                this.managers = result.map(manager => {
                    return new ManagerModel(manager);
                })
                return this.managers;
            });
        }
    }

    clear = () => {
        this.managers = [];
    }

}

const managersService = new ManagersService();
export default managersService;
