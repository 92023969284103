import React, { useState } from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { inject, observer } from "mobx-react";

const CHARS_LIMIT = 5;

const AnswerAdd = ({ reviewsStore, uiStore,  reviewId, addAnswer }) => {

    const [comment, setComment] = useState('');

    const saveAnswer = () => {
        reviewsStore.saveAnswer(reviewId, comment)
            .then(data => {
                data && setComment('');

                addAnswer({
                    id: data,
                    text: comment,
                    reviewId
                });
            })
            .catch(error => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });
            });
    }

    return (
        <div className={cn('replay__form', { 'active': comment })}>
            <textarea
                placeholder='Ваш комментарий'
                value={comment}
                onChange={(e) => {
                    setComment(e.target.value);
                }}
            />
            <div className={cn('replay__footer')}>
                <span className={'author'}>&nbsp;</span>
                <div className={cn('replay__btn_wrapper')}>
                    <Button
                        className={cn('replay__btn', { active: comment.length >= CHARS_LIMIT })}
                        onClick={saveAnswer}
                        disabled={comment.length <= CHARS_LIMIT}
                        label='→'
                    />
                </div>
            </div>
        </div>
    );
}

export default inject('reviewsStore', 'uiStore')(observer(AnswerAdd));
