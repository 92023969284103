import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import OkAnalytics from "../common/OkAnalytics";
import Header from "../Header";
import ApplicationList from "../pages/application/list/ApplicationList";
import ApplicationDetail from "../pages/application/detail/ApplicationDetail";
import ReviewsCommon from "../pages/analytics/reviews/Reviews";
import BranchCommon from "../pages/analytics/branch/Branch";
import TrafficCommon from "../pages/analytics/traffic/Traffic";
import ApplicationsCommon from "../pages/analytics/applications/Applications";
import ReviewListCommon from "../pages/review/ReviewListCommon"
import { inject, observer } from "mobx-react";


const IFrameRoute = withRouter(inject('userStore')(observer(({ userStore }) => {
    return (
        <React.Fragment>
            <Header />
            <div className={'content'}>
                <Switch>
                    {userStore.profile.allowed.listApplications &&
                        <Route path='/application/list' component={ApplicationList} />}
                    {userStore.profile.allowed.listApplications &&
                        <Route exact path='/application/detail/:id' component={ApplicationDetail} />}
                    {userStore.profile.allowed.listReviews &&
                        <Route path='/review/list' component={ReviewListCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/applications' component={ApplicationsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/reviews' component={ReviewsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/branch' component={BranchCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/traffic' component={TrafficCommon} />}
                    <Redirect to='/application/list' />
                </Switch>
            </div>
        </React.Fragment>
    );
})));


export default IFrameRoute;
