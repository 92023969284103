import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import OkAnalytics from "../common/OkAnalytics";

import Header from "../Header";
import Footer from "../Footer";
import ApplicationFormAdd from "../pages/application/form/ApplicationFormAdd";
import PromoPage from "../pages/promo/Promo";
import ApplicationList from "../pages/application/list/ApplicationList";
import Develop from "../pages/Develop";
import ApplicationDetail from "../pages/application/detail/ApplicationDetail";

import ReviewsCommon from "../pages/analytics/reviews/Reviews";
import BranchCommon from "../pages/analytics/branch/Branch";
import TrafficCommon from "../pages/analytics/traffic/Traffic";
import ApplicationsCommon from "../pages/analytics/applications/Applications";
import ReviewListCommon from "../pages/review/ReviewListCommon"
import { inject, observer } from "mobx-react";


const Internal = inject('userStore')(observer(({ userStore }) => {
    return (
        <React.Fragment>
            <Header />

            <div className={'content'}>
                <Switch>
                    <Route exact path='/' component={PromoPage} />
                    {userStore.profile.allowed.createApplication &&
                        <Route path='/application/add' component={ApplicationFormAdd} />}
                    {userStore.profile.allowed.listApplications &&
                        <Route path='/application/list' component={ApplicationList} />}
                    {userStore.profile.allowed.listApplications &&
                        <Route exact path='/application/detail/:id' component={ApplicationDetail} />}
                    {userStore.profile.allowed.listReviews &&
                        <Route path='/review/list' component={ReviewListCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/applications' component={ApplicationsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/reviews' component={ReviewsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/branch' component={BranchCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/traffic' component={TrafficCommon} />}
                    {userStore.profile.user.email === 'test-user3@example.com' &&
                        <Route path='/dev' component={Develop} />}
                    <Redirect to='/' />
                </Switch>
            </div>
            {process.env.REACT_APP_OAUTH2 === 'false' && <Footer />}
        </React.Fragment>
    );

}));

export default withRouter(OkAnalytics(Internal));
