import moment from 'moment-timezone';
import {ApplicationStatusModel} from "./application-status";
import {ManagerModel} from "./manager";
import {ServiceModel} from "./service";
import {CustomerModel, PurchaseModel} from "./purchase";
import ReviewModel from "./review";
import NpsModel from "./nps";

class ApplicationModel {
    constructor(application = {}) {

        this._id = application.id || null;
        this._number = application.number || null;
        this._time = application.time || null;
        this._status = new ApplicationStatusModel(application.status || {});
        this._nps = new NpsModel(application.nps || {});
        this._manager = new ManagerModel(application.manager || {});
        this._service = new ServiceModel(application.service || {});
        this._customer = new CustomerModel(application.customer || {});
        this._purchase = new PurchaseModel(application.purchase || {});
        this._review = new ReviewModel(application.review || {});
    }

    get id() {
        return this._id;
    }

    get number() {
        return this._number;
    }

    get time() {
        return moment
            .utc(this._time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }

    get status() {
        return this._status;
    }

    get nps() {
        return this._nps;
    }

    get manager() {
        return this._manager;
    }

    get service() {
        return this._service;
    }

    get customer() {
        return this._customer;
    }

    get purchase() {
        return this._purchase;
    }

    get review() {
        return this._review;
    }

}

export default ApplicationModel;
