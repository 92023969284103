import React from "react";

const PrepareNumber = ({number}) => {
    return (
        <>{number > 0
            ?
            `+${Number(number).toLocaleString('ru-RU')}`
            :
            `${Number(number).toLocaleString('ru-RU')}`}</>
    )
}

export default PrepareNumber;
