import Author from "./author";
import moment from "moment";

export default class Answer {
    constructor(answer) {
        this._id = answer.id || null;
        this._reviewId = answer.reviewId || null;
        this._parentId = answer.parentId || null;
        this._depth = answer.depth || 0;
        this._author = (answer.author) ? new Author(answer.author) : {};
        this._text = answer.text || '';
        this._answerable = answer.answerable || false;
        this._official = answer.official || false;
        this._okdAnswered = answer.okdAnswered || false;
        this._published = answer.published || false;
        this._postedAt = answer.postedAt || null;
        this._updatedAt = answer.updatedAt || null;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {int|null}
     */
    get reviewId() {
        return this._reviewId;
    }

    /**
     * @return {int|null}
     */
    get parentId() {
        return this._parentId;
    }

    /**
     * @return {int}
     */
    get depth() {
        return this._depth;
    }

    /**
     * @return {Author}
     */
    get author() {
        return this._author;
    }

    /**
     * @return {string}
     */
    get text() {
        return this._text;
    }

    /**
     * @return {boolean}
     */
    get answerable() {
        return this._answerable;
    }

    /**
     * @return {boolean}
     */
    get official() {
        return this._official;
    }

    /**
     * @return {boolean}
     */
    get okdAnswered() {
        return this._okdAnswered;
    }

    /**
     * @return {boolean}
     */
    get published() {
        return this._published;
    }

    /**
     * @return {string}
     */
    get postedAt() {
        const tmp  = this._postedAt ? moment(this._postedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
        return tmp
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this._updatedAt ? moment(this._updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

}
