import authService from "./auth";
import api from "./api";
import { ServiceModel } from "../models/service";

class AutoServicesService {
    #services = [];

    get services() {
        return this.#services;
    }

    set services(services) {
        this.#services = services;
    }

    getServices = () => {
        if (this.services.length > 0) {
            return Promise.resolve(this.services);
        } else {
            return api.get(
                `/services`,
            ).then((result) => {
                this.service = result.map(service => {
                    return new ServiceModel(service);
                })
                return this.service;
            });
        }
    }

    clear = () => {
        this.services = [];
    }

}

const autoServicesService = new AutoServicesService();
export default autoServicesService;
