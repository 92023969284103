import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterReview } from "../Filter";
import SourcePlate from "../SourcePlate"
import { inject, observer } from "mobx-react";
import PrepareNumber from "../../../../components/common/PrepareNumber";

const ReviewsDesktop = ({ analyticsStore }) => {

    const { filter } = analyticsStore;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState();

    useEffect(() => {
        setLoading(true);
        analyticsStore.getReviewsList()
            .then(result => {
                setItems(result);
                setLoading(false);
            });
    }, [JSON.stringify(filter)]);

    return (
        <>
            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs />
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterReview loading={loading} />
                </div>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'} />}
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Филиал</th>
                                        <th className={cn('source')}>Источник</th>
                                        <th className={cn('rating')}>Рейтинг</th>
                                        <th className={cn('scores', 'align__right')}>Оценки</th>
                                        <th>&nbsp;</th>
                                        <th className={cn('review', 'align__right')}>Отзывы</th>
                                        <th>&nbsp;</th>
                                        <th className={cn('align__right', 'answer')}>С ответом</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items?.total && <FirstRow
                                        data={items?.total}
                                    />}

                                    {items.items?.length > 0 && items.items.map((item, key) => (
                                        <Row
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const FirstRow = ({ data }) => {
    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')} />
                    <div>Все филиалы</div>
                </div>
            </td>
            <td className={cn('source')}>&nbsp;</td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')} />
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <span className={cn(
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber number={data.diff.rating} />
                    </span>
                </span>
            </td>

            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.scores) >= 0 },
                    { 'diff__down': Number(data.diff.scores) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.scores} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.reviews) >= 0 },
                    { 'diff__down': Number(data.diff.reviews) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.reviews} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {data.close.reviews ? ((data.close.responded / data.close.reviews) * 100).toFixed() : <>0</>}%
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const Row = ({ data }) => {
    const [title, title2] = data.branch.title.split(' - ');

    return (
        <tr>
            <td>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')} />
                    <div title={data.branch.title}>
                        {title} {!!title2 > 0 && <>-<br/>{title2}</>}
                    </div>
                </div>
            </td>
            <td className={cn('source')}>
                <SourcePlate
                    adplatforms={data.adplatforms}
                    sources={data.sources}
                />
            </td>

            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')} />
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <span className={cn(
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber number={data.diff.rating} />
                    </span>
                </span>
            </td>

            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.scores) >= 0 },
                    { 'diff__down': Number(data.diff.scores) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.scores} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <span className={cn(
                    'diff',
                    { 'diff__up': Number(data.diff.reviews) >= 0 },
                    { 'diff__down': Number(data.diff.reviews) < 0 }
                )}
                >
                    <PrepareNumber number={data.diff.reviews} />
                </span>
            </td>
            <td className={cn('align__right')}>
                {((data.close.responded / data.close.reviews) * 100).toFixed()}%
            </td>
        </tr>
    );
}


export default inject('analyticsStore')(observer(ReviewsDesktop));
