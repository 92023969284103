import React, {Component} from "react";
import cn from "classnames";

import './BlockRequest.scss';
import Block4Ico1 from "../../../images/block4_ico1.svg";
import Block4Ico2 from "../../../images/block4_ico2.svg";
import CloseIco from "../../../images/error_close_ico.svg";

import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Formik} from "formik";

import {ProgressSpinner} from "primereact/progressspinner";
import reviewRequestService from "../../../services/request"
import OkInputMask from "../application/common/OkInputMask";


const BlockRequest = (props) => {
    const {showForm} = props;
    return (
        <div className={cn('block', 'block4')}>
            <div className={'block__wrapper'}>
                <img src={Block4Ico1} alt="" className={'img1'}/>
                <img src={Block4Ico2} alt="" className={'img2'}/>
                <div className={'block__bg-transparent'}>
                    <div className={'block__text-content'}>
                        <h1 className={cn('block__title', 'title')}>Сервис уже доступен — подключайтесь прямо сейчас и получайте больше лидов</h1>
                    </div>
                    {showForm && <div className={'block__form'}>
                        <BlockRequestForm/>
                    </div>}
                    <div className={cn('contactus')}>
                        <div className={cn('contactus__mailto')}>
                            <a href="mailto:info@okreview.ru:">info@okreview.ru</a>
                        </div>
                        <div className={cn('contactus__tel')}>
                            <a href="tel:+79150054379">+7 (915) 005-43-79</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class BlockRequestForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            isLoading: false,
            success: false,
            recaptchaLoaded: false,
            initForm: {
                phone: '',
                name: '',
                autoDealer: ''
            }
        };
    }

    componentDidMount() {
        let script = document
            .createElement('script');

        script.setAttribute('id', 'recaptchaScript');
        script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_gRecaptchaKey}`);
        script.addEventListener('load', this.handleLoaded);
        document.body.appendChild(script);
    }

    componentWillUnmount() {
        const script = document
            .getElementById('recaptchaScript');

        script.removeEventListener('load', this.handleLoaded);
        script.remove();
    }

    handleLoaded = () => {
        this.setState({recaptchaLoaded: true});
    }

    onFormSubmit = (values) => {
        if (this.state.recaptchaLoaded === true) {
            this.setState({isLoading: true});
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(process.env.REACT_APP_gRecaptchaKey, {action: 'submit'})
                    .then(token => {
                        const newValues = {
                            ...values,
                            ...{token: token}
                        }

                        reviewRequestService.saveReviewRequest(newValues)
                            .then(() => {
                                this.setState({isLoading: false, success: true});
                            }).catch(error => {
                                console.log(error);
                                this.setState({isLoading: false, error: true});
                            }
                        );

                    })
            })
        }
    }

    errorClass = (isError) => {
        return (isError === true) ? 'p-error' : '';
    }

    validateForm = (values) => {
        const errors = {};
        values.phone = values.phone.trim();
        values.name = values.name.trim();
        values.autoDealer = values.autoDealer.trim();
        if (!values.phone) errors.phone = true;
        if (!values.name) errors.name = true;
        if (!values.autoDealer) errors.autoDealer = true;

        return errors;
    }

    render() {
        const {
            error,
            isLoading,
            initForm,
            success,
        } = this.state;


        return (
            <div className={cn('form__wrapper')}>

                {error && <Dialog visible={error}
                                  className={cn('dialogError')}
                                  position={'center'}
                                  onHide={() => this.setState({'error': false})}>
                    <div className={'blockError'}>
                        <div className={cn('wrapper', 'error')}>
                            <div className={cn('text')}
                                 dangerouslySetInnerHTML={{__html: process.env.REACT_APP_errorTextLoadData}}/>
                            <div
                                className={cn('close')}
                                onClick={() => this.setState({'error': false})}
                            ><img src={CloseIco} alt="" width={'15px'} height={'15px'}/></div>
                        </div>
                    </div>
                </Dialog>}

                {isLoading && <div className={'loading'}><ProgressSpinner/></div>}
                {!isLoading && <React.Fragment>
                    {(success) ?
                        <div className={cn('form__success')}>Спасибо за интерес к сервису OKReview - мы оперативно
                            свяжемся с Вами</div>
                        :
                        <React.Fragment>
                            <div className={cn('form__title')}>Оставьте заявку или свяжитесь c нами</div>
                            <Formik
                                initialValues={initForm}
                                validate={this.validateForm}
                                onSubmit={(values) => {
                                    this.onFormSubmit(values);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      setFieldValue,
                                      handleSubmit
                                  }) => (
                                    <form onSubmit={handleSubmit} id={'request-form'}>
                                        <div className={'form__field_wrapper'}>
                                            <div className={'text-field'}>
                                                <div className="p-float-label">
                                                    <OkInputMask
                                                        id='phone'
                                                        mask="+7 (n99) 999-99-99"
                                                        type="text"
                                                        name="phone"
                                                        className={this.errorClass((errors.phone && touched.phone))}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                    />


                                                    {(errors.phone && touched.phone) &&
                                                    <span className={'inputTextError'}>{error.phone}</span>}
                                                    <label htmlFor='phone'>
                                                        Телефон <span style={{color: 'red'}}>*</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={'text-field'}>
                                                <div className="p-float-label">
                                                    <InputText
                                                        id='name'
                                                        type="text"
                                                        name="name"
                                                        className={this.errorClass((errors.name && touched.name))}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                    {(errors.name && touched.name) &&
                                                    <span className={'inputTextError'}>{error.name}</span>}
                                                    <label htmlFor='name'>Имя и фамилия <span
                                                        style={{color: 'red'}}>*</span></label>
                                                </div>
                                            </div>
                                            <div className={'text-field'}>
                                                <div className="p-float-label">
                                                    <InputText
                                                        id='autoDealer'
                                                        type="text"
                                                        name="autoDealer"
                                                        className={this.errorClass((errors.autoDealer && touched.autoDealer))}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.autoDealer}
                                                    />
                                                    {(errors.autoDealer && touched.autoDealer) &&
                                                    <span className={'inputTextError'}>{error.autoDealer}</span>}
                                                    <label htmlFor='autoDealer'>Автодилер <span
                                                        style={{color: 'red'}}>*</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={'form__button_wrapper'}>
                                            <div className={'text-field'}>
                                                <Button label='Отправить заявку' type="submit"
                                                        className={cn('submit-btn')}/>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            <div
                                className="g-recaptcha"
                                data-sitekey={process.env.REACT_APP_gRecaptchaKey}
                                data-size="invisible"/>
                            <div className={cn('googlePrivacyPolicy')}>
                                This site is protected by reCAPTCHA and the Google <a
                                href="https://policies.google.com/privacy"
                                target='_blank' rel="noopener noreferrer">Privacy
                                Policy</a> and <a href="https://policies.google.com/terms" target='_blank'
                                                  rel="noopener noreferrer">Terms of Service</a> apply.
                            </div>
                        </React.Fragment>}
                </React.Fragment>}
            </div>
        );
    }
}

export default BlockRequest;
