import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import ChartTraffic from "../ChartTraffic";
import moment from "moment";
import toGis from '../../../../images/platforms/2gis_logo.png';
import avito from '../../../../images/platforms/avito_logo.png';
import flamp from '../../../../images/platforms/flamp_logo.png';
import okreview from '../../../../images/platforms/okreview_logo.png';
import yandex from '../../../../images/platforms/yandex_logo.png';


import Calendar from "../../review/filter/Calendar";

const TrafficDesktop = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        /*
                analyticsStore.getReviewsList()
                    .then(result => {
                        setItems(result);
                        setLoading(false);
                    });
        */
        setLoading(false);
    }, []);

    return (
        <>
            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs />
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterTraffic loading={loading} />
                </div>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'} />}
                        <ChartTraffic />
                    </div>
                </div>

                <div className={cn('analytics-footer')}>
                    <div className={cn('analytics-carousel')}>
                        <ul>
                            <li>
                                <img src={toGis} alt="" width={45} height={45}/>
                            </li>
                            <li>
                                <img src={avito} alt="" width={45} height={45} />
                            </li>
                            <li>
                                <img src={flamp} alt="" width={45} height={45} />
                            </li>
                            <li>
                                <img src={okreview} alt="" width={45} height={45} />
                            </li>
                            <li>
                                <img src={yandex} alt="" width={45} height={45} />
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

const FilterTraffic = () => {
    return (
        <>
            <div className={cn('filter__wrapper')}>
                <div className={'filter__item'}>
                    <Calendar
                        onChange={()=>{}}
                        dateRange={
                            {
                                from: moment(new Date()).subtract(1, 'month').toString(),
                                to: new Date()
                            }
                        }
                        shortFormat={false}
                    />
                </div>

            </div>
        </>
    );
}

export default TrafficDesktop;
