import React, {Component} from "react";
import {
    isMobile
} from "react-device-detect";
import '../../../App.scss';
import './ApplicationList.scss';
import ApplicationListDesktop from "./desktop/ApplicationListDesktop";
import ApplicationListMobile from "./mobile/ApplicationListMobile";
import okAnalyticsService from "../../../../services/okAnalytics";

class ApplicationList extends Component {
    componentDidMount() {
        okAnalyticsService.addEntity({
            page: 'application_list',
            target: 'page',
            event: 'open',
        });
    }

    getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    render() {
        return (
            <React.Fragment>
                {/* если мобила с большим экраном подгружаем версию для десктопа */}
                {(isMobile && this.getClientWidth() <= 1024) ? <ApplicationListMobile/> : <ApplicationListDesktop/>}
            </React.Fragment>
        );
    }
}

export default ApplicationList;
