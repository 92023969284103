import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import './profile.scss';

const Profile = ({ userStore, developersTagStore, handleOnClick }) => {

    const domNode = useRef();
    const [openPlate, setOpenPlate] = useState(false);
    const { isDeveloper } = developersTagStore;

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler);
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
        }
    }, [])

    const clickOutsideHandler = (event) => {
        if (!domNode.current?.contains(event.target)) {
            setOpenPlate(false)
        }
    }

    return (
        <div
            className={cn('profile', { active: openPlate })}
            ref={domNode}
        >
            <div
                className={cn('profile__user-block')}
                onClick={() => setOpenPlate(!openPlate)}
            >

                {isDeveloper ?
                    <i className={cn('profile__ico', 'pi', 'pi-cog')} /> :
                    <div className={cn('profile__ico', 'avatar', 'avatar__black', 'ico')} />}
                <div className={cn('profile__user-name')}>{userStore.userFullName}</div>
            </div>
            <div className={cn('profile__client-title')} title={userStore.clientTitle}>{userStore.clientTitle}</div>
            <div className={cn('profile__separator')} />
            <ul>
                <li
                    data-tag='logout'
                    onClick={handleOnClick}
                >
                    Выход
                </li>
            </ul>
        </div>
    );
}

export default inject('userStore', 'developersTagStore')(observer(Profile));
