import { action, observable } from "mobx";
import moment from "moment";
import _ from "underscore";

import analyticsService from '../services/analytics'
import reviewsService from '../services/review';
const REMOVE_BRANCH_TITLE = 'OKReview';
class AnalyticsStore {
    _analyticsService;
    _reviewsService;

    @observable filter = {
        date: {
            from: new Date(moment().subtract(1, 'month').toString()),
            to: new Date(),
        },
        //{value:1}
        branches: [],
        sources: [],
        scores: []
    }

    @observable filterBranch = {
        date: {
            from: new Date(moment().subtract(1, 'month').toString()),
            to: new Date(),
        },
        branches: [],
    }

    @observable branches = [];
    @observable sources = [];

    scores = [
        { value: 1, name: '1' },
        { value: 2, name: '2' },
        { value: 3, name: '3' },
        { value: 4, name: '4' },
        { value: 5, name: '5' },
    ];

    constructor(analyticsService, reviewsService) {
        this._analyticsService = analyticsService;
        this._reviewsService = reviewsService;
    }

    @action clearFilter() {
        this.filter = {
            date: {
                from: moment(new Date()).subtract(1, 'month').toString(),
                to: new Date(),
            },
            branches: [],
            sources: [],
            scores: []
        };
    }

    /**
     *
     * @param {string} fieldName
     * @param {any} fieldValue
     * @param {string} filter
     */
    @action setFieldValue = (fieldName, fieldValue, filter= 'filter') => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this[filter][fieldName] = fieldValue;
        }
    }
    /**
     *
     * @param {Object} date
     * @param {string} filter
     */
    @action setDate = (date, filter= 'filter') => {
        this[filter].date = date;
    }

    @action getBranches() {
        this._reviewsService
            .getBranch()
            .then(data => {
                this.branches = data.items?.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            });
    }

    @action getSources() {
        this._reviewsService
            .getSources()
            .then(data => {
                this.sources = data.items
                    .filter(item => item.title !== REMOVE_BRANCH_TITLE)
                    .map(item => (
                        {
                            value: item.id,
                            name: item.title,
                        }
                    ));
            });
    }

    /**
     *
     * @param {Object} filter
     * @returns {{date: {from: string, to: string}}}
     */
    parseFilterToApi = (filter = {}) => {
        let newObj = {
            date: {
                'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
            },
        };

        _.mapObject(filter, (val, key) => {
            if (_.isArray(val) && val.length > 0) {
                newObj[key] = filter[key].map(val => val.value).join(',');
            }
        });

        return newObj;
    }

    getReviewsByPlatformList = () => {
        return this._analyticsService.getReviewsByPlatformList(this.parseFilterToApi(this.filterBranch))
            .then(result => result)
            .catch(e => e);
    }



    getReviewsList = () => {
        return this._analyticsService.getReviewsList(this.parseFilterToApi(this.filter))
            .then(result => {
                return result;
            })
            .catch(e => e);
    }

    getApsBranchList = () => {
        return this._analyticsService.getApsBranchList(this.parseFilterToApi(this.filter))
            .then(result => {
                return result;
            })
            .catch(e => e);
    }

    getApsManagerByPlatformList = () => {
        return this._analyticsService.getApsManagerList(this.parseFilterToApi(this.filterBranch))
            .then(result => result)
            .catch(e => e);

    }

    getApsManagerList = () => {
        return this._analyticsService.getApsManagerList(this.parseFilterToApi(this.filter))
            .then(result => result)
            .catch(e => e);
    }

}

const analyticsStore = new AnalyticsStore(analyticsService, reviewsService);
export default analyticsStore;
