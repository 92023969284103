import authService from "./auth";
import api from './api';
import { serialize } from "../helpers/index";

class AnalyticsService {

    /**
     * аналитика отчет по филиалам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getReviewsList = (filter) => {
        const queryString = serialize(filter);
        return api.get(
            `/extreview/reviews/report/branches?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    getReviewsByPlatformList = (filter) => {
        const queryString = serialize(filter);
        return api.get(
            `/extreview/reviews/report/adplatforms?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }


    /**
     * аналитика отчет по филиалам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getApsBranchList = (filter) => {
        const queryString = serialize(filter);
        return api.get(
            `/applications/report/branches?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    /**
     * аналитика отчет по сотрудникам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getApsManagerList = (filter) => {
        const queryString = serialize(filter);
        return api.get(
            `/applications/report/managers?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);

    }

}

const analyticsService = new AnalyticsService();
export default analyticsService;
