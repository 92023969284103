import { action, observable } from "mobx";

class UiStore {
    @observable message = {
        severity: 'success',
        summary: 'header',
        detail: 'detail',
        sticky: true,
        life: 3000,
        closable: true,
    };

    @observable _loading = false;

    @action setMessage = (message = {}) => {
        this.message = {
            ...this.message,
            ...message,
        };
    }

    @action startLoading = () => {
        this._loading = true;
    }

    @action endLoading = () => {
        this._loading = false;
    }

    isLoading = () => {
        return this._loading;
    }
}

const uiStore = new UiStore();
export default uiStore;

