import moment from "moment-timezone";

import ReviewModel from "./review";
import NpsModel from "./nps";

class EventModel {
    constructor(event = {}) {
        this._id = event.id || null;
        this._time = event.time || null;
        this._event = event.event || '';
        this._nps = new NpsModel(event.nps || {});
        this._review = new ReviewModel(event.review || {});
    }

    get id() {
        return this._id;
    }

    get time() {
        return moment
            .utc(this._time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }

    get event() {
        return this._event;
    }

    get nps() {
        return this._nps;
    }

    get review() {
        return this._review;
    }

}

export default EventModel;
