import React, { useEffect, useRef, useState } from "react";
import { AD_PLATFORM_TYPE } from "../../../models/ad-platform";
import cn from "classnames";
import "./SourcePlate.scss";

import Yandex from '../../../images/icons/01.png';
import DGis from '../../../images/icons/02.png';
import Avito from '../../../images/icons/03.png';
import Irecommend from '../../../images/icons/04.png';
import Otzovik from '../../../images/icons/05.png';
import Flamp from '../../../images/icons/06.png';
import { getDataByPlatform } from "../../../helpers/index";

/**
 * "avito" "google" "yandex" "2gis" "otzovik" "flamp" "irecommend" "unknown"
 */

/**
 *
 * @param {string} type
 * @returns {{img, name: string}}
 */
const getLogoByPlatform = (type) => {
    switch (type) {
        case AD_PLATFORM_TYPE.YANDEX:
            return {
                name: 'Yandex',
                img: Yandex,
            };
        case AD_PLATFORM_TYPE.AVITO:
            return {
                name: 'Avito',
                img: Avito,
            };
        case AD_PLATFORM_TYPE.DGIS:
            return {
                name: '2Gis',
                img: DGis,
            };
        case AD_PLATFORM_TYPE.IRECOMMEND:
            return {
                name: 'irecommend',
                img: Irecommend,
            };
        case AD_PLATFORM_TYPE.OTZOVIK:
            return {
                name: 'irecommend',
                img: Irecommend,
            };
        case AD_PLATFORM_TYPE.FLAMP:
            return {
                name: 'Flamp',
                img: Flamp,
            };

        default:
            return {
                name: 'Otzovik',
                img: Otzovik,
            };
    }
};

/**
 *
 * @param {boolean} isMobile
 * @param {Array} sources Array [ "avito", "yandex" ]
 * @param {Array} adplatforms
 * @returns {JSX.Element}
 * @constructor
 */
const SourcePlate = ({ isMobile = false, sources = [], adplatforms = [] }) => {

    const sourcePlateRef = useRef();
    const [open, setOpen] = useState(false)

    const clickOutsideHandler = (event) => {
        if (!sourcePlateRef.current?.contains(event.target)) {
            setOpen(false);
        }
    }

    const handleOpen = () => {
        setOpen(open => !open);
    }

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    return (
        <div
            ref={sourcePlateRef}
            className={cn('source__wrapper', 'source', { 'mobile': isMobile }, { 'open': open })}
        >
            <ul
                onClick={handleOpen}
            >
                {adplatforms.map((item, key) => {
                    const { img } = getDataByPlatform(item.code);
                    return (
                        <li key={key}>
                            <a href={item.url} target="_blank">
                                <img src={img} alt={item.title} title={item.title} width={26} height={26} />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default SourcePlate;
