import { inject, observer } from "mobx-react";
import React, { forwardRef, useEffect, useState } from "react";
import cn from "classnames";
import DatePicker from "react-datepicker";
import { useIsMount } from "../../../../hooks";
import { InputText } from "primereact/inputtext";

const OkFilterCalendar = ({ filterStore }) => {

    const [startDate, setStartDate] = useState(filterStore.filter.date.from);
    const [endDate, setEndDate] = useState(filterStore.filter.date.to);
    const isMount = useIsMount();

    useEffect(() => {
        if (!isMount) {
            setStartDate(filterStore.filter.date.from);
            setEndDate(filterStore.filter.date.to);
        }
    }, [JSON.stringify(filterStore.filter.date)])


    useEffect(() => {
        if (!isMount) {
            filterStore.setFieldValue('date', {
                from: startDate,
                to: endDate,
            });
        }

    }, [startDate, endDate]);

    const ContainerCalendar = forwardRef(
        ({ value, onClick }, ref) => {
            return (
                <span className="p-input-icon-right">
                    <i className="pi pi-calendar"
                       onClick={onClick}
                    />
                        <InputText
                            readOnly
                            value={value}
                            onClick={onClick}
                            ref={ref}
                        />
                </span>
            )
        }
    );

    return (
        <React.Fragment>
            <div className={'text-field dateFrom-field'}>
                <label htmlFor="date[from]">с</label>
                <div className={cn('data-text__wrapper')}>
                    <DatePicker
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        calendarClassName="okCalendar"
                        onChange={(date) => setStartDate(date)}
                        locale="ru"
                        dateFormat={'dd.MM.yyyy'}
                        calendarStartDay={1}
                        customInput={<ContainerCalendar />}
                    />
                </div>
            </div>

            <div className={'text-field dateTo-field'}>
                <label htmlFor="date[to]">по</label>
                <div className={cn('data-text__wrapper')}>
                    <DatePicker
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        calendarClassName="okCalendar"
                        onChange={(date) => setEndDate(date)}
                        locale="ru"
                        dateFormat={'dd.MM.yyyy'}
                        calendarStartDay={1}
                        customInput={<ContainerCalendar />}
                    />
                </div>
            </div>

        </React.Fragment>
    );
}
export default inject('filterStore')(observer(OkFilterCalendar));
