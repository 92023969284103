import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import cn from "classnames";
import {scroller} from "react-scroll";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {ScrollPanel} from 'primereact/scrollpanel';
import {
    isMobile
} from "react-device-detect";

import "./ApplicationDetail.scss"
import RatingField from "../common/RatingField";
import NpsBlock from "../common/NpsBlock";
import applicationService from "../../../../services/application";
import okAnalyticsService from "../../../../services/okAnalytics";
import ApplicationModel from "../../../../models/application";
import EventModel from "../../../../models/event";
import {Link} from "react-router-dom";


@inject('filterStore', 'userStore')
@observer
class ApplicationDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            isLoading: true,
            application: {},
            events: []
        };

        scroller.scrollTo('root', {
            duration: 0,
        });


        const idApplication = Number(this.props.match.params.id);

        applicationService.getApplications(idApplication)
            .then(data => {
                this.setState({
                    application: new ApplicationModel(data)
                });
                return applicationService.getEvents(idApplication)
            })
            .then(data => {

                const events = [];

                data.forEach(i => {
                    events.push(new EventModel(i));
                });

                this.setState({
                    isLoading: false,
                    events: events
                });
            })
            .catch(() => {
                this.setState({
                    error: true
                });
            });

    }

    componentDidMount() {
        okAnalyticsService.addEntity({
            page: 'application_detail',
            target: 'page',
            event: 'open',
        });
    }

    render() {
        const {
            isLoading,
            error,
            application,
            events,
        } = this.state;

        let classNameForm = 'application-detail';
        if (isLoading) {
            classNameForm += ' application-detail__bg-transparent';
        }

        return (
            <React.Fragment>
                <div className={cn('page__application-detail', 'page')}>
                    <div className={classNameForm}>
                        {error && <Dialog visible={error}
                                            className={cn('dialogError')}
                                            position={'center'}
                                            onHide={() => this.setState({'error': false})}>
                            <div className={'blockError'}>
                                <div className={cn('wrapper', 'error')}>
                                    <div className={cn('text')}
                                         dangerouslySetInnerHTML={{__html: process.env.REACT_APP_errorTextSendForm}}/>
                                </div>
                            </div>

                        </Dialog>}
                        {!error && isLoading && <div className={'loading'}><ProgressSpinner/></div>}
                        {!isLoading && <React.Fragment>
                            <div className={cn('application-detail__cart')}>
                                <div className={cn('cart__wrapper')}>
                                    <h1>Заявка {application.number}</h1>
                                    <div className={cn('service')}>{application.service.title}</div>
                                    <div className={cn('status')}>
                                        <span className={`p-tag application-tag`}
                                              style={{
                                                  'color': application.status.foreground,
                                                  'backgroundColor': application.status.background
                                              }}>
                                        {application.status.title.toUpperCase()}
                                    </span>
                                    </div>
                                    <div className={cn('descr')}>
                                        <div className={cn('row')}>
                                            <div className={cn('col-title')}>Дата заявки</div>
                                            <div
                                                className={cn('col-text')}>{application.time}</div>
                                        </div>
                                        <div className={cn('row')}>
                                            <div className={cn('col-title')}>Менеджер</div>
                                            <div className={cn('col-text')}>{application.manager.fullName}</div>
                                        </div>
                                    </div>

                                    <div className={cn('field-box')}>

                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Марка</span>
                                            <div
                                                className={cn('field__text')}>{application.purchase.car.brand}</div>
                                        </div>

                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Модель</span>
                                            <div
                                                className={cn('field__text')}>{application.purchase.car.model}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Год</span>
                                            <div className={cn('field__text')}>{application.purchase.car.year}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Цвет</span>
                                            <div
                                                className={cn('field__text')}>{application.purchase.car.color}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>VIN</span>
                                            <div className={cn('field__text')}>{application.purchase.car.vin}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Телефон</span>
                                            <div className={cn('field__text')}>{application.customer.phone}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Имя клиента</span>
                                            <div
                                                className={cn('field__text')}>{application.customer.firstname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Фамилия клиента</span>
                                            <div className={cn('field__text')}>{application.customer.lastname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>E-mail клиента</span>
                                            <div className={cn('field__text')}>{application.customer.email}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Дата сделки</span>
                                            <div
                                                className={cn('field__text')}>{application.purchase.dateFormatted}</div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className={cn('application-detail__lent')}>
                                <div className={cn('lent__wrapper')}>
                                    {!isMobile ? <ScrollPanel className={cn('scroll-panel')}>
                                        <ul>
                                            {events.map((item, key) => (
                                                <Event
                                                    item={item}
                                                    key={key}
                                                />
                                            ))}
                                        </ul>
                                    </ScrollPanel> : <ul>
                                        {events.map((item, key) =>  (
                                            <Event
                                                item={item}
                                                key={key}
                                            />
                                        ))}
                                    </ul>}
                                </div>
                                <div className={cn('form-btn__wrapper')}>
                                    <Link to='/Application/list' className="p-button p-component back-btn">
                                        <span className="p-button-label p-c">Вернуться</span></Link>
                                </div>

                            </div>

                        </React.Fragment>}
                        {/* TODO вставить обертку для кнопки во всех формах */}
                        {/* TODO стили кнопки вынести в общий css */}
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

const Event = ({item}) => {

    return (
        <li>
            <div>
                <div className={cn('date')}>{item.time}</div>
                <div className={cn('text')}>{item.event} {item.nps.score && <NpsBlock nps={ item.nps || {} }/>}</div>
            </div>
            {item.review.text && <Review
                item={item.review}
            />}
        </li>
    )
}

const Review = (props) => {
    const {item} = props;
    return (
        <div className={cn('review')}>
            <div className={cn('review__header')}>
                <span className={cn('name')}>{item.author.lastname} {item.author.firstname}</span>
                <span className={cn('rating')}>
                    <RatingField
                        review={
                            {rating: item.rating}
                        }
                    />
               </span>
            </div>
            <div className={cn('review__body')}>
                {item.text}
            </div>
        </div>
    )
}


export default ApplicationDetail
