import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import { inject, observer } from "mobx-react";
import { useIsMount } from "../../../../hooks/index";

const Grade = ({ chartStore, reviewFilterStore }) => {

    am4core.useTheme(am4themes_animated);

    const chart = useRef(null);
    const isMount = useIsMount();
    const chartsFilter = reviewFilterStore.parseFilterToChart();
    let x = null;

    const initChart = (data = []) => {
        x = am4core.create("chart-source4", am4charts.XYChart);
        x.padding(0, 60, 30, 0);
        x.maskBullets = false;

        let categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "rating";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let valueAxis = x.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "rating";
        series.dataFields.valueX = "value";
        series.columns.template.fill = am4core.color("#FFC547");
        series.columns.template.tooltipText = "Оценка: {rating}\nКол-во: {value}"
        series.columns.template.strokeWidth = 0;
        series.columns.template.height = 10;

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [0.5, 1];
        fillModifier.offsets = [0, 1];
        series.columns.template.fillModifier = fillModifier;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.truncate = false;
        labelBullet.label.dx = 5;
        labelBullet.label.text = "{value} [font-size: 14px {textColor}]{new}[/]";
        labelBullet.locationX = 0;

        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'grade')
            .then((data) => {
                initChart(data.data);
            });
        return () => destroyChart();
    }, []);

    useEffect(() => {
        if(!isMount) {
            chartStore.getData(chartsFilter, 'grade')
                .then((data) => {
                    initChart(data.data);
                });
        }
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset>
            <legend>Распределение оценок</legend>
            <div id='chart-source4' style={{ width: '100%', height: '100%' }} />
        </fieldset>
    )
}
export default inject('chartStore', 'reviewFilterStore')(observer(Grade));

