import React, { forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import './Calendar.scss';

registerLocale('ru', ru);

const Calendar = ({ disabled = false, dateRange, onChange=()=>{}, shortFormat= false} ) => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const dateFormat = shortFormat ? 'dd.MM.yy' : 'dd.MM.yyyy';
    const nowDate = new Date();

    useEffect(() => {
        setStartDate(new Date(dateRange.from));
        setEndDate(new Date(dateRange.to));
    }, [JSON.stringify(dateRange)])

    const StartContainerCalendar = forwardRef(
        ({ value, onClick }, ref) => {
            return (
                <div
                    className={cn('calendar', 'calendar__left')}
                    onClick={onClick}
                    ref={ref}
                >{value}</div>
            )
        }
    );

    const EndContainerCalendar = forwardRef(
        ({ value, onClick }, ref) => {
            return (
                <div
                    className={cn('calendar', 'calendar__right')}
                    onClick={onClick}
                    ref={ref}
                >{value}</div>
            )
        }
    );

    return (
        <div className={cn('calendar__wrapper', {'disabled': disabled})}>
            <DatePicker
                disabled={disabled}
                selected={startDate}
                calendarClassName="okCalendar"
                onChange={(date) => setStartDate(() => {
                    onChange({
                        from: date,
                        to: endDate
                    });

                    return date;
                })}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                locale="ru"
                dateFormat={dateFormat}
                calendarStartDay={1}
                customInput={<StartContainerCalendar />}
            />
            <div className={cn('calendar__separator')}>-</div>
            <DatePicker
                disabled={disabled}
                selected={endDate}
                calendarClassName="okCalendar"
                onChange={(date) => setEndDate(()=> {
                    onChange({
                        from: startDate,
                        to: date
                    });

                    return date
                })}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={nowDate}
                calendarStartDay={1}
                locale="ru"
                dateFormat={dateFormat}
                customInput={<EndContainerCalendar />}
            />
        </div>
    );
}

export default Calendar;
