import api from './api';
import { jsonToFormUrl } from "../helpers/index";

const RTOKEN_INVALID_ERROR_CODE = [8];

class AuthService2 {
    /**
     *
     * @type {boolean}
     */
    #isAuthorized = false;

    /**
     *
     * @type {string|null}
     */
    #accessToken = null;

    /**
     *
     * @type {string|null}
     */
    #refreshToken = null;

    /**
     *
     * @type {string|null}
     */
    #accessTs = null;

    /**
     *
     * @type {null}
     */
    #iFrameAuthCode = null;

    /**
     *
     * @type {Object|null}
     */
    #api = null;


    #refreshRequest = null;

    constructor(api) {
        this.#api = api;
        this.#iFrameAuthCode = new URLSearchParams(window.location.search)
            .get(process.env.REACT_APP_IFRAMEAUTHCODE) || null;

        this.#api.client.interceptors.request.use(config => {

                if (!this.#accessToken) {
                    return config;
                }

                const newConfig = {
                    headers: {},
                    ...config
                };

                newConfig.headers.Authorization = `Bearer ${this.#accessToken}`;
                return newConfig
            },
            e => Promise.reject(e));

        this.#api.client.interceptors.response.use(
            r => r,
            async error => {
                if (!this.#refreshToken || error.response.status !== 401 || error.config.retry) {
                    throw error;
                }

                if (!this.#refreshRequest) {
                    if (RTOKEN_INVALID_ERROR_CODE.includes(error.response.data.error.data.code)) {
                        //this.removeAppStorage();
                        //window.location.replace('/login');
                    }

                    let bodyFormData = new FormData();
                    bodyFormData.append('grant_type', 'refresh_token');
                    bodyFormData.append('refresh_token', `${this.#refreshToken}`);

                    this.#refreshRequest = this.#api.client.post(`${process.env.REACT_APP_apiUrl}/auth/login`, bodyFormData, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    });
                }

                const { data } = await this.#refreshRequest;
                if(data) {
                    this.setTokens(data.data);
                    this.setAuthorized(true);
                    //this.saveInAppStorage(data.data);
                }


                const newRequest = {
                    ...error.config,
                    retry: true,
                };
                this.#refreshRequest = null;
                return this.#api.client(newRequest);
            }
        );
    }

    init() {
        return new Promise((resolve, reject) => {
// этот блок для отладки и тестов без auth_code от maxposter
            if (process.env.REACT_APP_OAUTH2 === 'true' && this.#iFrameAuthCode === null) {
                return this.login('test-user3@example.com', '1234567890')
                    .then(result => {
                        this.#iFrameAuthCode = result.data.auth_code;
                    })
                    .then(() => {
                        return this.auth();
                    })
                    .then(result => {
                        this.setTokens(result.data);
                        this.setAuthorized(true);
                        resolve(true);
                    })
                    .catch(e => {
                        reject(e);
                    });
            }
// этот блок будет страбатывать если от maxposert в get будет auth_code
            else if (process.env.REACT_APP_OAUTH2 === 'true' && this.#iFrameAuthCode !== null) {
                this.auth()
                    .then(result => {
                        this.setTokens(result.data);
                        this.setAuthorized(true);
                        resolve(true);
                    })
                    .catch(e => {
                        reject(e);
                    });

            } else {
                console.log('somebody error');
            }
        });
    }

    /**
     *
     * @returns {{accessToken: (string|null), accessTs: (string|null), refreshToken: (string|null)}}
     */
    getTokens() {
        return {
            accessToken: this.#accessToken,
            refreshToken: this.#refreshToken,
            accessTs: this.#accessTs,
        }
    }

    /**
     *
     * @param {Object} data
     */
    setTokens(data) {
        this.#accessToken = data.access_token;
        this.#refreshToken = data.refresh_token;
        this.#accessTs = data.expires_in;
    }

    saveTokens() {

    }

    restoreTokens() {

    }

    /**
     *
     * @param {Boolean} authorize
     */
    setAuthorized(authorize = false) {
        this.#isAuthorized = authorize;
    }


    /**
     * тестовый логин для получения auth_code
     * @param {String} username
     * @param {String} password
     * @returns {Promise<unknown>}
     */
    login(username, password) {
        const formBody = jsonToFormUrl({ username, password })

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_apiUrl}/auth/authorize`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody,
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /**
     *
     * @returns {Promise<unknown>}
     */
    auth() {
        if (this.#iFrameAuthCode) {
            const formBody = jsonToFormUrl({ code: this.#iFrameAuthCode })
            return new Promise((resolve, reject) => {
                fetch(`${process.env.REACT_APP_apiUrl}/auth/access_token`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: formBody,
                    }
                )
                    .then(response => {
                        return response.json();
                    })
                    .then(result => {
                        if (result.statusCode === 200) {
                            resolve(result);
                        } else {
                            reject(result);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        }
    }

    /**
     *
     * @param {String} username
     * @param {String} password
     * @param {Boolean} remember
     */
    mainLogin(username, password, remember) {
        const formBody = jsonToFormUrl(
            {
                'grant_type': 'password',
                username,
                password,
            }
        );

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_apiUrl}/auth/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody,
                }
            )
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    if (result.statusCode === 200) {
                        resolve();
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    reject(e);
                })
        });

    }

    saveIFrameAuthCode() {
        //TODO охранить в сторе?
    }

    logout() {
    }

    isAuthorized() {
        return this.#isAuthorized;
    }



}

const OAuth = new AuthService2(api);

export default OAuth;
