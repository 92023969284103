import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import { inject, observer } from "mobx-react";
import { useIsMount } from "../../../../hooks/index";

const Pie = ({ chartStore, reviewFilterStore }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const isMount = useIsMount();
    const chartsFilter = reviewFilterStore.parseFilterToChart();
    let x = null;

    const initChart = (data = [], summary) => {
        x = am4core.create('chart-source', am4charts.PieChart);
        x.padding(0, 65, 20, 65);
        x.maskBullets = false;
        x.innerRadius = am4core.percent(40);
        let series = x.series.push(new am4charts.PieSeries());

        series.dataFields.value = "count";
        series.dataFields.category = "source";

        series.labels.template.text = "[#7A7A7A]{source}[/]\n{count}\n[{textColor}]{change}[/]";

        series.slices.template.propertyFields.fill = "color";
        series.slices.template.tooltipText = "";


        let rgm = new am4core.RadialGradientModifier();
        rgm.opacities.push(1, 0.5, 1, 0.5, 1);
        series.slices.template.fillModifier = rgm;
        series.slices.template.strokeModifier = rgm;
        series.slices.template.strokeOpacity = 0.6;
        series.slices.template.strokeWidth = 0;

        let label = series.createChild(am4core.Label);
        label.text = summary.total;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 16;

        x.data = data;

        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'pie')
            .then((data) => {
                initChart(data.data, data.summary);
            });
        return () => destroyChart();
    }, []);

    useEffect(() => {
        if(!isMount) {
            chartStore.getData(chartsFilter, 'pie')
                .then((data) => {
                    initChart(data.data, data.summary);
                });
        }
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset>
            <legend>Источники</legend>
            <div id='chart-source' style={{ width: '100%', height: '100%' }} />
        </fieldset>
    );
}

export default inject('chartStore', 'reviewFilterStore')(observer(Pie));
