export class ServiceModel {
    constructor(service = {}) {
        this._id = service.id;
        this._type = new ServiceType(service.type);
        this._title = service.title;
        this._city = service.city;
        this._address = service.address;
        this._phone = service.phone;
        this._email = service.email;
        this._website = service.website;
    }

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    get title() {
        return this._title;
    }

    get city() {
        return this._city;
    }

    get address() {
        return this._address;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    get website() {
        return this._website;
    }
}

class ServiceType {
    constructor(type = {}) {
        this._code = type.code;
        this._name = type.name;
    }

    get code() {
        return this._code;
    }

    get name() {
        return this._name;
    }


}