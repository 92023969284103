import React from 'react';
import { observer, inject } from "mobx-react";
import { isMobile } from "react-device-detect";
import External from "./layouts/External";
import Internal from "./layouts/Internal";
import IFrameRoute from "./layouts/IFrameRoute";

import YandexMetrika from "./common/YandexMetrika";
import Message from "./common/Message";
import './App.scss';

const App = () => {
    return (
        <>
            {process.env.REACT_APP_OAUTH2 === 'true'? <IFrameApp /> : <MainApp />}
        </>
    );
}

const IFrameApp = inject('userStore')(observer(({ userStore }) => {
    return (
        <React.Fragment>
            {!!userStore.isAuthorized && <IFrameRoute />}
        </React.Fragment>
    )
}));

const MainApp = inject('userStore')(observer(({ userStore }) => {

    return (
        <React.Fragment>
            {<YandexMetrika />}
            {userStore.isAuthorized !== null ? <React.Fragment>
                {userStore.isAuthorized ? <Internal /> : <External />}
            </React.Fragment> : ''}
            <Message isMobile={isMobile} />
        </React.Fragment>
    )
}));


export default App;

