const VALIDATION_ERROR_CODE = 422;
const DEFAULT_ERROR_MESSAGE = 'Возникла неизвестная ошибка. Просим обратиться в поддержку +7 (915) 005-43-79';
const DEFAULT_ERROR_DESC = 'Заявка не создана';

class ErrorService {
    _error = {};

    constructor(error) {
        this._error = error || {};
    }

    set error(error) {
        this._error = error
    }

    get error() {
        return this._error;
    }

    /**
     * @param {string} desc
     * @param {string} message
     * @return {{}|{error: {data: [{message}], description, type: string}}}
     */
    getError (desc= '', message= '') {
        if(this.error.statusCode === VALIDATION_ERROR_CODE) {
            return this.error;
        } else {
            return {
                "error": {
                    "type": "DEFAULT_ERROR",
                    "description": desc || DEFAULT_ERROR_DESC,
                    "data": [
                        {
                            "message": message || DEFAULT_ERROR_MESSAGE,
                        },
                    ]
                }
            }
        }
    }

}

export default ErrorService;
