import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";

import { Rating } from "primereact/rating";
import { Button } from "primereact/button";

import { pluralize } from "../../../../../helpers";
import BranchesBlock from "../../list/common/BranchesBlock";
import AnswerList from "../../../../../components/pages/review/list/AnswerList";
import AnswerAdd from "../../../../../components/pages/review/list/common/AnswerAdd";
import SourceBlock from "../../../../../components/pages/review/list/common/SourceBlock";
import AnswerButton from "../../../../../components/pages/review/list/common/AnswerButton";
import ClaimsSlideBar from "../../../../../components/pages/review/list/common/ClaimsSlideBar";
import ClaimStatus from "../../../../../components/pages/review/list/common/ClaimStatus";

const ReviewItemMobile = ({ data, reviewsStore }) => {

    const [showQuestionsListBlock, setShowQuestionsListBlock] = useState(false);
    const [showAnswerBlock, setShowAnswerBlock] = useState(false);
    const [answerList, setAnswerList] = useState([]);
    const [visibleRight, setVisibleRight] = useState(false);
    const [review, setReview] = useState(data);
    const [toggle, setToggle] = useState(true);
    const [answer, setAnswer] = useState({});

    const [claimsAllowed, setClaimsAllowed] = useState(false);
    const [claimStatus, setClaimStatus] = useState('');


    useEffect(() => {
        setAnswer(getText());
        setClaimsAllowed(data.claim.allowed);
        setClaimStatus(data.claim.status);

    }, []);

    useEffect(() => {
        if (showQuestionsListBlock === true) {
            reviewsStore.loadAnswers(data.id)
                .then((result) => {
                    setAnswerList(result);
                });
        }
    }, [showQuestionsListBlock]);

    const getText = () => {
        const textArr = review.text.replaceAll(/\n\n+/g, "\n").split('\n');

        const text = textArr.map((item, key) => {
            return <span key={key}>{item}<br /></span>
        });
        const showShadow = !!(textArr.length > 5 || review.text.length > 250);

        return {
            text,
            showShadow,
            log: `строк ${textArr.length}. символов ${review.text.length}.`
        }
    }

    const addAnswer = (data) => {
        reviewsStore.loadReview(data.reviewId)
            .then((result) => {
                setReview(result)
            });

        const newComment = reviewsStore.addNewCommentInList(data.id, data.reviewId, data.text);
        setAnswerList(answerList => [...answerList, newComment]);
        setShowQuestionsListBlock(true);
        setShowAnswerBlock(false);
    }

    return (
        <div className={cn('review-list__item', 'item')}>
            <div className={cn('item__wrapper')}>
                <div className={cn('item__body-header')}>
                    <div className={cn('item__rating')}>
                        <SourceBlock
                            item={review}
                        />

                        <Rating
                            className={cn('rating', 'no-cursor', 'mobile')}
                            value={review.score}
                            cancel={false}
                        />
                    </div>
                    <div className={cn('item__status')}>
                        {review.published &&
                        <div className={cn('status', 'type1')}>Опубликован</div>}
                        {!review.published &&
                        <div className={cn('status', 'type2')}>Не опубликован</div>}
                        {review.type === 'application' &&
                        <div className={cn('status', 'type3')}>OKR заявка</div>}
                        {!review.answer.official &&
                        <div className={cn('status', 'type4')}>Без оф. ответа</div>}
                    </div>
                </div>

                <div className={cn('item__source')}>
                    <BranchesBlock
                        branches={review.branches}
                    />
                </div>

                <div className={cn('item__author')}>
                    <span>{!review.author.anonymous && review.author.name}</span>
                    <br />
                    {review.postedAt}
                </div>

                <div className={cn('item__body', { 'hide': (toggle && answer.showShadow) })}>
                    {answer.text}
                    <div
                        className={cn('shadow')}
                        onClick={() => {
                            setToggle(false);
                        }}
                    />
                </div>

                <div className={cn('show-more-btn')}>
                    {(toggle && answer.showShadow) && <>
                        <a
                            href="#"
                            onClick={(e) => {
                                setToggle(false);
                                return e.preventDefault();
                            }}
                        >Показать больше</a>
                    </>}
                </div>

                {/*TODO кнопку открытия ответов - в отдельный компонент и тут и в десктопе */}
                <div className={cn('replay-toggle-btn')}>
                    <div className={cn('replay-toggle-btn__wrapper')}>
                        {review.answer.count > 0 && <>
                            <a
                                href="#"
                                onClick={(e) => {
                                    setShowQuestionsListBlock((showQuestionsListBlock) => !showQuestionsListBlock);
                                    return e.preventDefault();
                                }}
                            >
                                {!showQuestionsListBlock && <>
                                    <span className={cn('badge')}>{review.answer.count}</span>
                                    {pluralize(review.answer.count, ['ответ', 'ответа', 'ответов'], false)}
                                    {
                                        !review.answer.official
                                            ?
                                            ' (нет официальных)'
                                            :
                                            ` (${pluralize(review.answer.official, ['официальный', 'официальных', 'официальных'])})`}
                                </>}
                                {showQuestionsListBlock && <>
                                    {review.answer.count > 1 && 'Скрыть ответы'}
                                    {review.answer.count < 2 && 'Скрыть ответ'}
                                </>}
                            </a>
                        </>}
                        <span className={cn('no-answer')}
                        >{!review.answer.count && 'Нет ответов'}</span>
                    </div>

                    {showQuestionsListBlock && <>
                        <AnswerList
                            items={answerList}
                        />
                    </>}

                    {showAnswerBlock && <>
                        <AnswerAdd
                            reviewId={review.id}
                            addAnswer={addAnswer}
                        />
                    </>}

                </div>

                <div className={cn('item__answer-btn')}>
                    <AnswerButton
                        item={review}
                        setShowAnswerBlock={setShowAnswerBlock}
                    />
                </div>

                <div className={cn('item__jeremiad-btn')}>
                    {claimsAllowed ? <>
                            <Button
                                label='Обжаловать отзыв'
                                className={cn('p-button-outlined')}
                                onClick={() => setVisibleRight(true)}
                            />

                            <ClaimsSlideBar
                                reviewId={review.id}
                                setClaimsAllowed={setClaimsAllowed}
                                setClaimStatus={setClaimStatus}
                                visibleRight={visibleRight}
                                setVisibleRight={setVisibleRight}
                                isMobile={true}
                            />
                        </>
                        : <ClaimStatus status={claimStatus}/>}
                </div>
{/*
        {claimsAllowed ? <>
                <Button
                    label='Обжаловать отзыв'
                    className={cn('p-button-outlined')}
                    onClick={() => setVisibleRight(true)}
                />

                <ClaimsSlideBar
                    reviewId={review.id}
                    setClaimsAllowed={setClaimsAllowed}
                    setClaimStatus={setClaimStatus}
                    visibleRight={visibleRight}
                    setVisibleRight={setVisibleRight}
                />
            </>
            : <ClaimStatus status={claimStatus}/>}
*/}

            </div>
        </div>
    );
}

export default inject('reviewsStore', 'reviewFilterStore')(observer(ReviewItemMobile));
